import React from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col } from 'react-bootstrap'

import VoltageCodeControl from './VoltageCodeControl.js'
import CapacitorValueControl from './CapacitorValueControl.js'
import ToleranceCodeControl from './ToleranceCodeControl.js'
import TerminationCodeControl from './TerminationCodeControl.js'
import MarkingCodeControl from './MarkingCodeControl.js'
import SeriesNameControl from './SeriesNameControl.js'
import PackagingCodeControl from './PackagingCodeControl.js'
import PartNumberDisplay from './PartNumberDisplay.js'

@observer
class CapacitorSelectionPanel extends React.Component {
  render() {
    return (
      <div className="panel panel-primary">
        <div className="panel-heading">
          <h3 className="panel-title" style={{fontWeight: 'normal'}}>High-Q Capacitor Selection</h3>
        </div>
        <div className="panel-body">
          <Form horizontal onSubmit={(e) => e.preventDefault() }>
            <Row>
              <Col md={6}>
                <SeriesNameControl />
              </Col>
              <Col md={6}>
                <CapacitorValueControl />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <ToleranceCodeControl />
              </Col>
              <Col md={6}>
                <VoltageCodeControl />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TerminationCodeControl />
              </Col>
              <Col md={6}>
                <MarkingCodeControl />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <PackagingCodeControl />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="panel-footer">
          <PartNumberDisplay />
        </div>
      </div>
    )
  }
}

export default CapacitorSelectionPanel;
