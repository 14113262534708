import React from 'react';
import { Button, ButtonGroup, Glyphicon, Modal } from 'react-bootstrap'
import { observer } from 'mobx-react';
import OrderSampleForm from './OrderSampleForm.js'
import cart from './cart.js'

@observer
class OrderSampleModal extends React.Component {
  closeOrderModal() {
    cart.showOrderModal = false
    cart.showOrderForm = false
  }

  showOrderForm() {
    cart.showOrderForm = true
  }

  partContent() {
    return (
      <div>
        <p>A maximum of 3 items can be ordered at one time.  The following items are on your list:</p>
        <ul>
          {cart.part1 && 
            <li>
              {cart.part1}
              &nbsp;
              <Glyphicon glyph="remove" style={{color: "#aaa", cursor: 'pointer'}} onClick={(e) => cart.removePart1()}></Glyphicon>
            </li>
          }
          {cart.part2 && 
            <li>
              {cart.part2}
              &nbsp;
              <Glyphicon glyph="remove" style={{color: "#aaa", cursor: 'pointer'}} onClick={(e) => cart.removePart2()}></Glyphicon>
            </li>
          }
          {cart.part3 && 
            <li>
              {cart.part3}
              &nbsp;
              <Glyphicon glyph="remove" style={{color: "#aaa", cursor: 'pointer'}} onClick={(e) => cart.removePart3()}></Glyphicon>
            </li>
          }
        </ul>
        <div style={{textAlign: "center"}}>
          <ButtonGroup>
            {cart.readyForOrder && 
              <Button bsStyle="primary" onClick={this.showOrderForm.bind(this)}><Glyphicon glyph="envelope"></Glyphicon> Complete Order</Button>
            }
            <Button bsStyle="default" onClick={this.closeOrderModal.bind(this)}>Close and add more</Button>
          </ButtonGroup>
        </div>
      </div>
    )
  }

  formContent() {
    return (
      <OrderSampleForm />
    )
  }

  modalSize() {
    return cart.showOrderForm ? "large" : null
  }

  render() {
    let modalBody = cart.showOrderForm ? this.formContent() : this.partContent()

    return (
      <Modal show={cart.showOrderModal} onHide={this.closeOrderModal.bind(this)} bsSize={this.modalSize()}>
        <Modal.Header closeButton>
          <Modal.Title>Order Samples</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalBody}
        </Modal.Body>
      </Modal>
    )
  }
}

export default OrderSampleModal
