import React from 'react';
import { Modal } from 'react-bootstrap'
import { observer } from 'mobx-react'
import loginStore from './loginStore'

@observer
class LoginModal extends React.Component {
  closeLoginModal = () => {
    loginStore.hideLoginModal()
  }

  render() {
    return (
      <Modal show={loginStore.loginModalVisible} onHide={this.closeLoginModal}>
        <Modal.Header closeButton>
          <Modal.Title>Please Log In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This feature requires a free account to be created.  Please <a href="/users/sign_in">login</a> or <a href="/users/sign_up">sign up</a> for an account.</p>
        </Modal.Body>
      </Modal>
    )
  }
}

export default LoginModal
