import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'

@observer
class ZPhaseDisplay extends React.Component {

  render() {
    return (
      <UIStatic
        label="Z ∠ϴ"
        value={calculator.zPhaseDegrees && calculator.zPhaseDegrees.toFixed(1)}
        unit="°"
      />
    )
  }
}

export default ZPhaseDisplay;
