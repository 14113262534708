import React from 'react';
import { Button, Glyphicon, Modal } from 'react-bootstrap'
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import sParameterOrder from './sParameterOrder.js'
import loginStore from './loginStore'

@observer
class SParametersButton extends React.Component {
  handleClick() {
    loginStore.requireLoggedInUser(() => {
      sParameterOrder.part = calculator.partNumber
      sParameterOrder.showOrderModal = true
    })
  }

  render() {
    return (
      <Button bsStyle="default" bsSize="small" block={this.props.block} onClick={this.handleClick}><Glyphicon glyph="envelope"></Glyphicon> Request for S-Parameters</Button>
    )
  }
}

export default SParametersButton
