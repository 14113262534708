import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'

@observer
class ESRDisplay extends React.Component {
  render() {
    return (
      <div>
        <UIStatic
          label="ESR"
          value={calculator.adjustedEsrOhms && (calculator.adjustedEsrOhms * 1000).toFixed(2)}
          unit="mΩ"
        />
      </div>
    )
  }
}

export default ESRDisplay;
