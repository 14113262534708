import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'
import UnitConverter from './UnitConverter.js'

@observer
class SRFDisplay extends React.Component {
  render() {
    return (
      <UIStatic
        label="SRF"
        value={UnitConverter.megahertzToGigahertz(calculator.srfMegahertz).toFixed(2)}
        unit="GHz"
      />
    )
  }
}

export default SRFDisplay;
