import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import HorizontalUIRadioGroup from './HorizontalUIRadioGroup.js'
import VerticalUIRadioGroup from './VerticalUIRadioGroup.js'
import UINumber from './UINumber.js'
import UISelect from './UISelect.js'
import { Row, Col, FormGroup, ControlLabel, Radio, Checkbox, Form, FormControl } from 'react-bootstrap'

@observer
class ChartDataSourceControl extends React.Component {

  render() {
    return (
      <Form horizontal onSubmit={(e) => e.preventDefault() }>
        <Row>
          <Col xs={4} sm={2} smOffset={1} md={2} mdOffset={0} lg={2} lgOffset={1}>
            <ControlLabel style={{textAlign: "right"}}>Range</ControlLabel>
          </Col>
          <Col xs={8} sm={5} md={5} lg={3}>
            <UINumber
              label="Start"
              labelStyle={{fontWeight: "normal", fontSize: "0.9em"}}
              value={calculator.startX}
              onChangeDelayed={(value) => calculator.startX = parseFloat(value)}
              validateOnChange={(e) => {
                let val = parseFloat(e.target.value)
                if(val < calculator.minX) {
                  return `Must be at least ${calculator.minX}`
                }
                else if(val > calculator.maxX) {
                  return `Must be less than or equal to ${calculator.maxX}`
                }
                else if(val > calculator.endX) {
                  return `Must be less than End`
                }
              }}
              bsSize="sm"
            />
          </Col>
          <Col xs={8} xsOffset={4} sm={5} md={5} smOffset={3} mdOffset={0} lg={3} lgOffset={0}>
            <UINumber
              label="End"
              labelStyle={{fontWeight: "normal", fontSize: "0.9em"}}
              value={calculator.endX}
              onChangeDelayed={(value) => calculator.endX = parseFloat(value)}
              validateOnChange={(e) => {
                let val = parseFloat(e.target.value)
                if(val < calculator.minX) {
                  return `Must be at least ${calculator.minX}`
                }
                else if(val > calculator.maxX) {
                  return `Must be less than or equal to ${calculator.maxX}`
                }
                else if(val < calculator.startX) {
                  return `Must be greater than Start`
                }
              }}
              bsSize="sm"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        <Row className="hidden-xs">
          <Col xs={12} xsOffset={0}>
            <HorizontalUIRadioGroup
              name1="chart-control-1"
              name2="chart-control-2"
              row1label="Left Scale"
              row2label="Right Scale"
              row1value={calculator.chartMode1Radio}
              row2value={calculator.chartMode2Radio}
              onRow1Change={(e) => calculator.chartMode1 = e.target.value}
              onRow2Change={(e) => calculator.chartMode2 = e.target.value}
              options={Object.values(calculator.chartAxisSelectors)}
              row1Disabledvalues={calculator.chartYAxis1DisabledValues}
              row2Disabledvalues={calculator.chartYAxis2DisabledValues}
            />
          </Col>
        </Row>
        <Row className="visible-xs-block">
          <Col xs={12}>
            <VerticalUIRadioGroup
              name1="chart-control-1"
              name2="chart-control-2"
              row1label="Left Scale"
              row2label="Right Scale"
              row1value={calculator.chartMode1Radio}
              row2value={calculator.chartMode2Radio}
              onRow1Change={(e) => calculator.chartMode1 = e.target.value}
              onRow2Change={(e) => calculator.chartMode2 = e.target.value}
              options={Object.values(calculator.chartAxisSelectors)}
              row2Disabledvalues={calculator.chartYAxis2DisabledValues}
            />
          </Col>
        </Row>
      </Form>
    )
  }
}

export default ChartDataSourceControl;
