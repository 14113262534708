var math = require('mathjs')
math.config({number: 'BigNumber'})

class UnitConverter {
  static megahertzToGigahertz(megahertz) {
    return math.eval(`megahertz / 10^3`, {megahertz: megahertz})
  }
}

export default UnitConverter;
