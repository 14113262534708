var includes = require('lodash/includes');

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, ControlLabel, Radio } from 'react-bootstrap'
import constants from './constants.js'

class HorizontalUIRadioGroup extends React.Component {
  render() {
    return (
      <FormGroup>
        <div style={{display: 'table', width: '100%', tableLayout: 'fixed'}}>
          <div style={{display: 'table-row'}}>
            <div style={{display: 'table-cell', textAlign: 'center' }}>&nbsp;</div>
            {this.props.options.map((option) =>
              <div style={{display: 'table-cell', textAlign: 'center' }} key={option.key}>
                <ControlLabel style={{textAlign: 'center', fontWeight: 'normal', fontSize: '0.9em', display: 'block', width: '100%'}}>{option.label}</ControlLabel>
              </div>
            )}
          </div>
          <div style={{display: 'table-row'}}>
            <div style={{display: 'table-cell', textAlign: 'center', color: constants.chartYAxis1Color }}>{this.props.row1label}</div>
            {this.props.options.map((option) =>
              <div style={{display: 'table-cell', textAlign: 'center' }} key={option.key}>
                <Radio
                  name={this.props.name1}
                  checked={this.props.row1value === option.yAxis}
                  value={option.yAxis}
                  onChange={this.props.onRow1Change}
                  style={{textAlign: 'center'}}
                  disabled={includes(this.props.row1Disabledvalues, option.yAxis)}
                />
              </div>
            )}
          </div>
          <div style={{display: 'table-row'}}>
            <div style={{display: 'table-cell', textAlign: 'center', color: constants.chartYAxis2Color }}>{this.props.row2label}</div>
            {this.props.options.map((option) =>
              <div style={{display: 'table-cell', textAlign: 'center' }} key={option.key}>
                <Radio
                  name={this.props.name2}
                  checked={this.props.row2value === option.yAxis}
                  value={option.yAxis}
                  onChange={this.props.onRow2Change}
                  style={{textAlign: 'center'}}
                  disabled={includes(this.props.row2Disabledvalues, option.yAxis)}
                />
              </div>
            )}
          </div>
        </div>
      </FormGroup>
    )
  }
}

HorizontalUIRadioGroup.propTypes = {
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string.isRequired,
  row1label: PropTypes.string.isRequired,
  row2label: PropTypes.string.isRequired,
  row1value: PropTypes.any,
  row2value: PropTypes.any,
  onRow1Change: PropTypes.func.isRequired,
  onRow2Change: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
}

export default HorizontalUIRadioGroup;

        // <Row>
        //   <Col xs={1}></Col>
        //   {this.props.options.map((option) =>
        //     <Col xs={1} key={option} style={{padding: 0}}>
        //       <ControlLabel style={{textAlign: 'center', fontWeight: 'normal', fontSize: '0.9em', display: 'block', width: '100%'}}>{option}</ControlLabel>
        //     </Col>
        //   )}
        // </Row>
        // <Row>
        //   <Col xs={1} style={{textAlign: "right"}}>
        //     <ControlLabel style={{ color: "#337AB7", paddingTop: 8}}>{this.props.row1label}</ControlLabel>
        //   </Col>
        //   {this.props.options.map((option) =>
        //     <Col xs={1} key={`row1-${option}`}>
        //       <Radio
        //         name={this.props.name1}
        //         checked={this.props.row1value === option}
        //         value={option}
        //         onChange={this.props.onRow1Change}
        //         style={{textAlign: 'center'}}
        //       />
        //     </Col>
        //   )}
        // </Row>
        // <Row>
        //   <Col xs={1} style={{textAlign: "right"}}>
        //     <ControlLabel style={{color: "#82ca9d", paddingTop: 8}}>{this.props.row2label}</ControlLabel>
        //   </Col>
        //   {this.props.options.map((option) =>
        //     <Col xs={1} key={`row2-${option}`}>
        //       <Radio
        //         name={this.props.name2}
        //         checked={this.props.row2value === option}
        //         value={option}
        //         onChange={this.props.onRow2Change}
        //         style={{textAlign: 'center'}}
        //       />
        //     </Col>
        //   )}
        // </Row>
