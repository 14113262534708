import { observable, computed, action, runInAction } from 'mobx'
var math = require('mathjs')
var range = require('lodash/range');
var find = require('lodash/find')
var findIndex = require('lodash/findIndex')
var includes = require('lodash/includes')
var min = require('lodash/min')
var max = require('lodash/max')
var concat = require('lodash/concat')
import constants from './constants.js'
import store from './store.js'
import chartAxisSelectors from './data/chartAxisSelectors.json'

math.config({number: 'BigNumber', predictable: true})

class ObservableDataStore {
  constructor() {
    store.afterFirstLoad = () => {
      this.frequencyMegahertz = store.frequencyMinMegahertz
      this.startX             = store.frequencyMinMegahertz
      this.minX               = store.frequencyMinMegahertz
      this.endX               = store.frequencyMaxMegahertz // We don't have fmax on initial load
      this.maxX               = store.frequencyMaxMegahertz // We don't have fmax on initial load
      this.activeChartFrequency = store.frequencyMinMegahertz
      this.resetSelectors()
    }

    store.afterFinalLoad = () => {
      this.endX = this.fmaxMegahertz
      this.maxX = this.fmaxMegahertz
    }
  }

  // Observable Values
  //
  
  @observable calculating                  = false
  @observable markingCodes                 = {}
  @observable packagingCodes               = {}
  @observable seriesCodes                  = {}
  @observable toleranceCodes               = {}
  @observable terminationCodes             = {}
  @observable voltageCodes                 = {}

  @observable __chartMode1                 = constants.initialChartMode1
  @observable __chartMode2                 = constants.initialChartMode2
  @observable chartMode1Radio              = constants.initialChartMode1
  @observable chartMode2Radio              = constants.initialChartMode2
  @observable numberofGraphPoints          = constants.initialNumberOfGraphPoints
  @observable frequencyMegahertz           = null
  @observable voltageCode                  = null
  @observable toleranceCode                = null
  @observable terminationCode              = null
  @observable markingCode                  = null
  @observable packagingCode                = null
  @observable ambientTemperatureCelcius    = constants.initialAmbientTemperatureCelcius
  @observable maxAllowedTemperatureCelcius = constants.initialMaxAllowedTemperatureCelcius
  @observable startX                       = 0
  @observable minX                         = 0
  @observable endX                         = 0
  @observable maxX                         = 0
  @observable chartAxisSelectors           = chartAxisSelectors

  @observable activeChartFrequency         = 0

  // Getters and Setters
  set chartMode1(value) {
    // There is a lag (particularly on slower browsers) between when the radio
    // is changed and when the graph calculations are ready.  This is makes the
    // app feel unresponsive.  By using a setTimeout we are able to make the
    // radio repond instantly.
    this.chartMode1Radio = value

    if(this.__chartMode1Timeout) {
      clearTimeout(this.__chartMode1Timeout)
      this.__chartMode1Timeout = null
    }

    this.__chartMode1Timeout = setTimeout(() => {
      runInAction("set __chartMode1Timeout", () => {
        let chartModeMeta = this.chartAxisSelectors[value]
        if(chartModeMeta.xAxis !== this.chartMode2Meta.xAxis) {
          this.chartMode2 = "none"
        }
        if(chartModeMeta.xAxis !== this.chartMode1Meta.xAxis) {
          if(chartModeMeta.xAxis === "capacitance") {
            this.startX = store.minCapacitancePicofarads
            this.minX = store.minCapacitancePicofarads
            this.endX = store.maxCapacitancePicofarads
            this.maxX = store.maxCapacitancePicofarads
            this.activeChartFrequency = store.minCapacitancePicofarads
          }
          else if(chartModeMeta.xAxis === "frequency") {
            this.startX = store.frequencyMinMegahertz
            this.minX = store.frequencyMinMegahertz
            this.endX = this.fmaxMegahertz || store.frequencyMaxMegahertz
            this.maxX = this.fmaxMegahertz || store.frequencyMaxMegahertz
            this.activeChartFrequency = store.frequencyMinMegahertz
          }
        }
        this.__chartMode1 = value
      })
    }, 100)
  }

  set chartMode2(value) {
    this.chartMode2Radio = value

    if(this.__chartMode2Timeout) {
      clearTimeout(this.__chartMode2Timeout)
      this.__chartMode1Timeout = null
    }

    this.__chartMode2Timeout = setTimeout(() => {
      this.__chartMode2 = value
    }, 100)
  }

  @computed get chartMode1() {
    return this.__chartMode1
  }

  @computed get chartMode2() {
    return this.__chartMode2
  }

  set capacitancePicofarads(value) {
    store.loading = true
    setTimeout(() => {
      store.capacitancePicofarads = value
      this.resetSelectors()
      store.loading = false
    }, 10)
  }


  @computed get capacitancePicofarads() {
    return store.capacitancePicofarads
  }

  set seriesName(value) {
    store.loading = true
    setTimeout(() => {
      store.seriesName = value
      this.resetSelectors()
      store.loading = false
    }, 10)
  }
  @computed get seriesName() {
    return store.seriesName
  }


  // Computed from JSON data
  //

  @computed get availableSeriesNames() {
    return store.seriesNames.map((v) => { return { name: this.seriesCodes[v] || v, value: v }})
  }

  @computed get capacitorCode() {
    return store.capacitorCode
  }

  @computed get capacitor() {
    return store.capacitor
  }

  @computed get voltageValue() {
    let voltageValue = this.voltageCodes[this.voltageCode]
    return parseFloat(voltageValue)
  }

  @computed get availableVoltageCodes() {
    return store.availableVoltageCodes.map((v) => { return { name: this.voltageCodes[v] || v, value: v }})
  }

  @computed get availableCapacitorValues() {
    return store.availableCapacitorValues
  }

  @computed get availableToleranceCodes() {
    return store.availableToleranceCodes.map((v) => { return { name: this.toleranceCodes[v] || v, value: v }})
  }

  @computed get availableTerminationCodes() {
    return store.availableTerminationCodes.map((v) => { return { name: this.terminationCodes[v] || v, value: v }})
  }

  @computed get availableMarkingCodes() {
    return store.availableMarkingCodes.map((v) => { return { name: this.markingCodes[v] || v, value: v }})
  }

  @computed get availablePackagingCodes() {
    return store.availablePackagingCodes.map((v) => { return { name: this.packagingCodes[v] || v, value: v}})
  }

  @computed get frequencyMinMegahertz() {
    return store.frequencyMinMegahertz
  }

  @computed get rthCelciusPerWatt() {
    return store.rthCelciusPerWatt
  }


  @computed get srfMegahertz() {
    return store.srfMegahertz
  }

  @computed get srfvMegahertz() {
    return store.srfvMegahertz
  }

  @computed get prfhMegahertz() {
    return store.prfhMegahertz
  }

  @computed get prfvMegahertz() {
    return store.prfvMegahertz
  }

  @computed get sParameterFileUrl() {
    return store.sParameterFileUrl
  }

  @computed get cadModelFileUrl() {
    return store.cadModelFileUrl
  }

  @computed get esrEstimates() {
    return store.esrEstimates
  }

  @computed get sParameters() {
    return store.sParameters
      .filter((sParameter) => sParameter.frequencyMegahertz >= store.frequencyMinMegahertz)
  }


  // Dynamically computed from a combination of data from JSON and various formulas
  //
  @computed get partNumber() {
    return [
      this.voltageCode,
      this.seriesName,
      this.capacitorCode,
      this.toleranceCode,
      this.terminationCode,
      this.markingCode,
      this.packagingCode
    ].join("")
  }

  @computed get estimatedEsrOhms() {
    return this.estimatedEsrOhmsAt(this.frequencyMegahertz)
  }

  @computed get y() {
    return this.esrFromS11OhmsAt(this.frequencyMegahertz)
  }

  @computed get extrapolatedEsrOhms() {
    return this.extrapolatedEsrOhmsAt(this.frequencyMegahertz)
  }

  @computed get adjustedEsrOhms() {
    // MobX not detecting these as dependencies by default
    let estimatedEsr = this.estimatedEsrOhms
    let y = this.y
    let extrapolatedEsrOhms = this.extrapolatedEsrOhms
    return this.extrapolatedAdjustedEsrOhmsAt(this.frequencyMegahertz)
  }

  @computed get eslNanohenries() {
    if(!this.capacitancePicofarads) {
      return NaN
    }

    let result = math.eval(`10^9 * 1/ (capacitancePicofarads * (10^-12) * (6.28 * srfMegahertz * (10^6))^2)` , {
        capacitancePicofarads: this.capacitancePicofarads,
        srfMegahertz: this.srfMegahertz})

    return math.bignumber(result)
  }

  @computed get maxEsrEstimateFrequency() {
    let frequencies = this.esrEstimates.map((e) => e.frequencyMegahertz)
    return frequencies[frequencies.length - 1]
  }

  @computed get magS11Decibels() {
    return this.magS11DecibelsAt(this.frequencyMegahertz)
  }

  @computed get magS21Decibels() {
    return this.magS21DecibelsAt(this.frequencyMegahertz)
  }

  @computed get ceffPicofarads() {
    return this.ceffPicofaradsAt(this.frequencyMegahertz)
  }

  @computed get q() {
    return this.qAt(this.frequencyMegahertz)
  }

  @computed get zOhms() {
    return this.zOhmsAt(this.frequencyMegahertz)
  }

  @computed get zPhaseDegrees() {
    return this.zPhaseDegreesAt(this.frequencyMegahertz)
  }

  @computed get lpmax() {
    if(this.ambientTemperatureCelcius >= this.maxAllowedTemperatureCelcius) {
      return NaN;
    }
    
    let pdissmaxWatt = math.eval(`(maxAllowedTemperatureCelcius - ambientTemperatureCelcius) / rthCelciusPerWatt`, {
      maxAllowedTemperatureCelcius: math.bignumber(this.maxAllowedTemperatureCelcius),
      ambientTemperatureCelcius: math.bignumber(this.ambientTemperatureCelcius),
      rthCelciusPerWatt: this.rthCelciusPerWatt})
    
    let lpmax = math.eval(`(pdissmaxWatt / voltageValue)^0.5`, {
      pdissmaxWatt: pdissmaxWatt,
      voltageCode: this.voltageValue})

    return math.bignumber(lpmax)
  }

  @computed get lmaxArms() {
    return this.lmaxArmsAt(this.frequencyMegahertz, this.capacitancePicofarads)
  }

  @computed get chartError() {
    if(this.chartMode1 == "none" && this.chartMode2 == "none") {
      return `No parameters selected`
    }
    else if(!this.mergedChartData.filter((p) => p.y1 || p.y2).length) {
      return `No data available`
    }
  }



  // Line Chart Meta
  //

  @computed get chartMode1Meta() {
    return this.chartAxisSelectors[this.chartMode1]
  }

  @computed get chartMode2Meta() {
    return this.chartAxisSelectors[this.chartMode2]
  }

  @computed get chartYAxis1DisabledValues() {
    if(calculator.sParameters.length === 0) {
      return [
        "prfh", "prfv", "s21_mag", "s21_phase", "s11_mag", "s11_phase"
      ]
    }
    else {
      return []
    }
  }

  @computed get chartYAxis2DisabledValues() {
    let xAxis = this.chartMode1Meta["xAxis"]
    let scale = this.chartMode1Meta["xAxisScale"]

    let disabledValues =  Object.values(this.chartAxisSelectors)
      .filter((n) => { return n["xAxis"] && n["xAxis"] !== xAxis })
      .filter((n) => { return n["xAxis"] && n["xAxisScale"] !== scale })
      .map((n) => { return n["yAxis"]})

    if(calculator.sParameters.length === 0) {
      disabledValues.push("prfh")
      disabledValues.push("prfv")
      disabledValues.push("s21_mag")
      disabledValues.push("s21_phase")
      disabledValues.push("s11_mag")
      disabledValues.push("s11_phase")
    }
    return disabledValues
  }

  @computed get xValues() {
    if(this.chartMode1Meta.xAxis == "capacitance") {
      return store.availableCapacitorValues
        .map((c) => parseFloat(c))
    }
    else {
      // return this.sParameters
      //   .map((f) => parseFloat(f.frequencyMegahertz))
      let chartXAxisDomain = this.chartXAxisDomain
      let start = chartXAxisDomain ? chartXAxisDomain[0] : this.startX
      let end   = chartXAxisDomain ? chartXAxisDomain[1] : this.endX
      let step  = (end - start) / this.numberofGraphPoints
      return range(start, end, step)
    }
  }

  @computed get chartXAxisDomain() {
    let ticks = this.chartXAxisTicks
    if(ticks) {
      return [ticks[0], ticks[ticks.length - 1]]
    }
    else {
      return null
    }
    // let xStartOffset = this.chartMode1Meta.xStartOffset || 0
    // let xEndOffset = this.chartMode1Meta.xEndOffset || 0
    // return [this.startX - xStartOffset , this.endX + xEndOffset]
  }

  @computed get chartXAxisLabel() {
    switch(this.chartMode1Meta.xAxis) {
      case "frequency":
        return "Frequency"
      case "capacitance":
        return "Capacitance"
      default:
        return "Frequency"
    }
  }

  @computed get chartXAxisLabelAbbrev() {
    switch(this.chartMode1Meta.xAxis) {
      case "frequency":
        return "F"
      case "capacitance":
        return "C"
      default:
        return "F"
    }
  }

  @computed get chartXAxisUnit() {
    switch(this.chartMode1Meta.xAxis) {
      case "frequency":
        return "MHz"
      case "capacitance":
        return "pF"
      default:
        return "MHz"
    }
  }

  @computed get chartXAxisTicks() {
    if(calculator.chartMode1Meta.xAxisScale == "log") {
      let points = this.mergedChartData
        .map((point) => { return point.x })
        .filter((n) => n != 0 && isFinite(n) && !isNaN(n))
      let min = Math.min(...points)
      let max = Math.max(...points)

      let ticks = this.distributePointsLogarithmically(min, max)
      return ticks
    }
    else {
      let ticks = this.distributePointsLinearly(this.startX, this.endX)

      if(ticks && this.startX == this.minX && this.endX == this.maxX && calculator.chartMode1Meta.forceXOrigin !== undefined && !ticks.includes(0)) {
        ticks.unshift(calculator.chartMode1Meta.forceXOrigin)
        ticks = ticks.sort((a, b) => a - b)
      }

      return ticks
    }
    // if(this.chartMode1Meta.xTicks) {
    //   let xStartOffset = this.chartMode1Meta.xStartOffset || 0
    //   let xEndOffset = this.chartMode1Meta.xEndOffset || 0

    //   return this.chartMode1Meta.xTicks
    //   .filter((t) => t >= this.startX - xStartOffset)
    //   .filter((t) => t <= this.endX + xEndOffset)
    // }
    // else {
    //   return null
    // }
  }


  @computed get chartYAxis1Ticks() {
    let points = this.mergedChartData
      .map((point) => { return point.y1 })
      .filter((n) => n != 0 && isFinite(n) && !isNaN(n))
    if(this.chartMode1 == "q_factor") {
      points = points.filter((n) => n >= 0.1)
    }
    let min = Math.min(...points)
    let max = Math.max(...points)


    if(calculator.chartMode1Meta.yAxisScale == "log") {
      let ticks = this.distributePointsLogarithmically(min, max)
      return ticks
    }
    else {
      let ticks = this.distributePointsLinearly(min, max)
      if(ticks && this.startX == this.minX && this.endX == this.maxX && calculator.chartMode1Meta.forceYOrigin !== undefined && !ticks.includes(0)) {
        ticks.unshift(calculator.chartMode1Meta.forceYOrigin)
        ticks = ticks.sort((a, b) => a - b)
      }
      return ticks
    }
  }

  @computed get chartYAxis2Ticks() {
    let points = this.mergedChartData
      .map((point) => { return point.y2 })
      .filter((n) => n != 0 && isFinite(n) && !isNaN(n))
    if(this.chartMode1 == "q_factor") {
      points = points.filter((n) => n >= 0.1)
    }
    let min = Math.min(...points)
    let max = Math.max(...points)

    if(calculator.chartMode2Meta.yAxisScale == "log") {
      let ticks = this.distributePointsLogarithmically(min, max)
      return ticks
    }
    else {
      let ticks = this.distributePointsLinearly(min, max)
      if(ticks && this.startX == this.minX && this.endX == this.maxX && calculator.chartMode2Meta.forceYOrigin !== undefined && !ticks.includes(0)) {
        ticks.unshift(calculator.chartMode2Meta.forceYOrigin)
        ticks = ticks.sort((a, b) => a - b)
      }
      return ticks
    }
  }

  @computed get chartYAxis1Domain() {
    let ticks = this.chartYAxis1Ticks
    if(ticks) {
      let temp =  [ticks[0], ticks[ticks.length - 1]]
      return [ticks[0], ticks[ticks.length - 1]]
    }
    else {
      return null
    }
  }

  @computed get chartYAxis2Domain() {
    let ticks = this.chartYAxis2Ticks
    if(ticks) {
      return [ticks[0], ticks[ticks.length - 1]]
    }
    else {
      return null
    }
  }

  @computed get chartSharedYAxisDomain() {
    let chartYAxis1Domain = this.chartYAxis1Domain
    let chartYAxis2Domain = this.chartYAxis2Domain
    
    return [
      Math.min(chartYAxis1Domain[0], chartYAxis2Domain[0]),
      Math.max(chartYAxis1Domain[1], chartYAxis2Domain[1])
    ]
  }

  @computed get shareYAxisDomain() {
    return includes(this.chartMode1Meta.shareYAxisWith, this.chartMode2)
  }

  @computed get lineTooltip() {
    let frequencyLabel = (this.chartMode1Meta.labelFrequency  || this.chartMode2Meta.labelFrequency) ?
      `(Freq = ${this.frequencyMegahertz} MHz)` :
      ''
    if(calculator.chartMode1Meta.xAxisScale !== calculator.chartMode1Meta.yAxisScale) {
      return `${calculator.chartMode1Meta.label} (${calculator.chartMode1Meta.xAxisScale}/${calculator.chartMode1Meta.yAxisScale}) ${frequencyLabel}`
    }
    else {
      return `${calculator.chartMode1Meta.label} (${calculator.chartMode1Meta.xAxisScale}) ${frequencyLabel}`
    }
  }

  @computed get chartLegend() {
    return `${calculator.chartXAxisLabel} (${calculator.chartXAxisUnit})`
  }

  @computed get chartYAxis1Label() {
    let unit = this.chartMode1Meta.yAxisUnit ? `(${this.chartMode1Meta.yAxisUnit})` : ''
    return `${this.chartMode1Meta.yAxisLabel || this.chartMode1Meta.label} ${unit}`;
  }

  @computed get chartYAxis2Label() {
    let unit = this.chartMode2Meta.yAxisUnit ? `(${this.chartMode2Meta.yAxisUnit})` : ''
    return `${this.chartMode2Meta.yAxisLabel || this.chartMode2Meta.label} ${unit}`;
  }

  @computed get mergedChartData() {
    if(!store.capacitor) {
      return null
    }

    let xValues = this.xValues
    let mergedData

    if(this.chartMode1 === 'none') {
      mergedData = xValues.map((x) => {
        let y2 = this.y2At(x)
        return { x: x, y2: this.toNumber(y2)}
      })
    } else if(this.chartMode2 === 'none') {
      mergedData = xValues.map((x) => {
        let y1 = this.y1At(x)
        return { x: x, y1: this.toNumber(y1)}
      })
    } else {
      mergedData = xValues.map((x) => {
        let y1 = this.y1At(x)
        let y2 = this.y2At(x)
        return { x: x, y1: this.toNumber(y1), y2: this.toNumber(y2)}
      })
    }

    mergedData = mergedData.filter((p) => p.x >= this.startX && p.x <= this.endX)

    // Log 0 is undefined, so charting a log chart with all 0's does not work.
    // Set to an extremely small value instead
    if(this.chartMode1Meta.xAxisScale === "log") {
      mergedData = mergedData.map((coords) => {
        if(coords["y1"] === 0) {
          coords["y1"] = 0.000001
        }
        if(coords["y2"] === 0) {
          coords["y2"] = 0.000001
        }
        return coords
      })
    }

    return mergedData
  }


  // Methods to find the nearest point in our JSON data
  //
  closestEsrMilliohmsEstimatesAt(frequencyMegahertz) {
    let esrEstimates = this.esrEstimates
    if(!esrEstimates || esrEstimates.length < 2) {
      return null
    }

    if(frequencyMegahertz < esrEstimates[0].frequencyMegahertz) {
      return [esrEstimates[0], esrEstimates[1]]
    }
    else if(frequencyMegahertz > esrEstimates[esrEstimates.length - 1].frequencyMegahertz) {
      return [esrEstimates[esrEstimates.length - 2], esrEstimates[esrEstimates.length - 1]]
    }
    else {
      let previous = this.esrEstimates[0]
      for(let i = 0; i < this.esrEstimates.length; i++) {
        if(this.esrEstimates[i].frequencyMegahertz >= frequencyMegahertz) {
          return [previous, this.esrEstimates[i]]
        }
        previous = this.esrEstimates[i]
      }
    }
  }

  closestSParametersAt(frequencyMegahertz) {
    let sParameters = this.sParameters
    let index = findIndex(sParameters, function(s) { return s.frequencyMegahertz >= frequencyMegahertz } )
    let previousIndex = Math.max(0, index - 1)
    return [sParameters[previousIndex], sParameters[index]]
  }

  closestCapacitanceAt(capacitance) {
    let capacitors = store.capacitors
    let index = findIndex(capacitors, function(n) { return n.capacitancePicofarads >= capacitance })
    let previousIndex = Math.max(0, index - 1)
    return [capacitors[previousIndex], capacitors[index]]
  }

  // Methods to calculate a value at an arbitrary frequency
  //

  estimatedEsrOhmsAt(frequencyMegahertz) {
    let closestEsrEstimates = this.closestEsrMilliohmsEstimatesAt(frequencyMegahertz)
    if(!closestEsrEstimates) {
      return NaN
    }
    let previous = closestEsrEstimates[0]
    let next = closestEsrEstimates[1]

    if(frequencyMegahertz > next.frequencyMegahertz || frequencyMegahertz < previous.frequencyMegahertz) {
      return NaN
    }

    // Interpolate
    let result = this.logarithmicInterpolate({
      x: frequencyMegahertz,
      previousX: previous.frequencyMegahertz,
      nextX: next.frequencyMegahertz,
      previousY: previous.esrMilliohms,
      nextY: next.esrMilliohms
    })

    return math.bignumber(result / 1000)
  }

  extrapolatedEsrOhmsAt(frequencyMegahertz) {
    if(!frequencyMegahertz) {
      return NaN
    }

    if(frequencyMegahertz <= this.maxEsrEstimateFrequency) {
      return this.estimatedEsrOhmsAt(frequencyMegahertz)
    }
    else {
      let maxEsrEstimateFrequency = this.maxEsrEstimateFrequency
      let maxEsrFromEstimates = this.estimatedEsrOhmsAt(maxEsrEstimateFrequency)

      if(!maxEsrFromEstimates || !maxEsrEstimateFrequency) {
        return NaN
      }

      // Extrapolation
      let result = math.eval(
        `maxEsrFromEstimates * (frequencyMegahertz / maxEsrEstimateFrequency)^logSlope`, {
          maxEsrFromEstimates: math.bignumber(maxEsrFromEstimates),
          frequencyMegahertz: math.bignumber(frequencyMegahertz),
          maxEsrEstimateFrequency: math.bignumber(maxEsrEstimateFrequency),
          logSlope: math.bignumber(constants.logSlope)
      })
      return math.bignumber(result)
    }
  }

  esrFromS11OhmsAt(frequencyMegahertz) {
    let sParameters = this.sParameters
    let sParameter = find(sParameters, (esrData) => esrData.frequencyMegahertz == frequencyMegahertz)

    if(sParameter) {
      let r = math.eval(`magS11 * cos((angS11 * pi / 180) rad)`, {
        magS11: sParameter.magS11, angS11: sParameter.angS11})
      let x = math.eval(`magS11 * sin((angS11 * pi / 180) rad)`, {
        magS11: sParameter.magS11, angS11: sParameter.angS11})
      let esrOhms = math.eval(`50 * ((1 - r^2 - x^2) / ((1 - r)^2 + x^2 ) - 1 )`, { r: r, x: x })
      return esrOhms
    }
    else {
      return NaN
    }
  }

  @computed get faMegahertz() {
    return 2000
  }

  @computed get fbMegahertz() {
    return math.eval(`1/3 * prfhMegahertz`, {prfhMegahertz: this.prfhMegahertz})
  }

  @computed get fmaxMegahertz() {
    let prfvMegahertz = this.prfvMegahertz
    let prfhMegahertz = this.prfhMegahertz
    let sParameters = this.sParameters
    let sParameterFrequenciesMegahertz = this.sParameters.map((s) => s.frequencyMegahertz)
    let providedfrequencyMaxMegahertz = store.frequencyMaxMegahertz

    if(prfvMegahertz) {
      return math.min(10000, prfvMegahertz * 1.2, providedfrequencyMaxMegahertz)
    }
    else if(prfhMegahertz) {
      return math.min(10000, prfhMegahertz * 1.2, providedfrequencyMaxMegahertz)
    }
    else {
      if(!sParameters.length) {
        return providedfrequencyMaxMegahertz
      }

      let maxSParameterFrequency = math.max(sParameterFrequenciesMegahertz)
      return math.min(10000, maxSParameterFrequency, providedfrequencyMaxMegahertz)
    }
  }


  @computed get fcMegahertz() {
    let fminMegahertz = this.fminMegahertz || store.frequencyMinMegahertz
    let fmaxMegahertz = this.fmaxMegahertz

    if(fminMegahertz === undefined) {
      return NaN
    }

    let sParameterFrequenciesMegahertz = this.sParameters
      .map((s) => s.frequencyMegahertz)
      .filter((f) => f >= fminMegahertz)
      // .filter((f) => f <= fmaxMegahertz)

    if(!sParameterFrequenciesMegahertz.length) {
      return NaN
    }
    let prfhMegahertz = this.prfhMegahertz
    let fbMegahertz = this.fbMegahertz


    if(prfhMegahertz) {
      let esrFromS11Ohms = sParameterFrequenciesMegahertz
        .map((f) => { return {f: f, y: this.esrFromS11OhmsAt(f)} })
      for(let i = 1; i < esrFromS11Ohms.length; i++) {
        let f1 = math.bignumber(esrFromS11Ohms[i - 1].f)
        let y1 = math.bignumber(esrFromS11Ohms[i - 1].y * 1000)
        let f2 = math.bignumber(esrFromS11Ohms[i].f)
        let y2 = math.bignumber(esrFromS11Ohms[i].y * 1000)

        let slope = math.eval(`(y2 - y1) / (f2 - f1)`, {y1: y1, y2: y2, f1: f1, f2: f2})
        esrFromS11Ohms[i].slope = slope
      }

      let esrData = find(esrFromS11Ohms, (esrData) => { return esrData.slope > 0 && esrData.f > fbMegahertz })
      if(esrData) {
        return math.bignumber(esrData.f)
      }
    }

    // If prfh not defined, then fc is the highest frequency that is < fa
    let index = findIndex(sParameterFrequenciesMegahertz, (f) => { return f > constants.fcCutoff } )
    let previousIndex = Math.max(0, index - 1)
    return math.bignumber(sParameterFrequenciesMegahertz[previousIndex])
  }

  @computed get offsetAtFc() {
    let fcMegahertz = this.fcMegahertz
    let esrAtFc = this.esrOhmsAtFc
    let yAtFc = this.esrFromS11OhmsAt(fcMegahertz)

    return math.eval(`esrAtFc - yAtFc`, { esrAtFc: esrAtFc, yAtFc: yAtFc })
  }

  @computed get esrOhmsAtFc() {
    let fcMegahertz = this.fcMegahertz
    return this.extrapolatedEsrOhmsAt(this.fcMegahertz)
  }

  // New1
  extrapolatedAdjustedEsrOhmsAt(frequencyMegahertz) {
    let calculatedValues = this.completeAdjustedEsrOhms

    let nextIndex = findIndex(calculatedValues, (v) => v.frequencyMegahertz >= frequencyMegahertz )
    let previousIndex = Math.max(0, nextIndex - 1)
    
    let previousValue = calculatedValues[previousIndex]
    let nextValue = calculatedValues[nextIndex]

    if(previousValue === undefined || nextValue === undefined) {
      return NaN
    }

    let result = this.logarithmicInterpolate({
      x: frequencyMegahertz,
      previousX: previousValue.frequencyMegahertz,
      nextX: nextValue.frequencyMegahertz,
      previousY: previousValue.esrOhms,
      nextY: nextValue.esrOhms
    })
    return result
  }

  // New2
  @computed get completeAdjustedEsrOhms() {
    let sParameters = this.sParameters
    let esrEstimates = this.esrEstimates
    let faMegahertz = this.faMegahertz
    let fbMegahertz = this.fbMegahertz
    let fcMegahertz = this.fcMegahertz
    let fmaxMegahertz= this.fmaxMegahertz
    let fminMegahertz = this.frequencyMinMegahertz

    let esrEstimateFrequencies = esrEstimates
      .map((e) => e.frequencyMegahertz)
      .filter((f) => f < fcMegahertz || isNaN(fcMegahertz))
    let sParameterFrequencies = sParameters
      .map((e) => e.frequencyMegahertz)
      .filter((f) => f >= fcMegahertz )

    let frequencies = concat(esrEstimateFrequencies, sParameterFrequencies)

    if(frequencies && frequencies[0] && frequencies[0] > this.frequencyMinMegahertz) {
      frequencies.unshift(this.frequencyMinMegahertz)
    }

    let completeAdjustedEsrOhms = frequencies.map((frequencyMegahertz) => {
      if(frequencyMegahertz === null) {
        return {frequencyMegahertz: frequencyMegahertz, esrOhms: NaN}
      }

      if(!this.sParameters.length) {
        return {frequencyMegahertz: frequencyMegahertz, esrOhms: this.extrapolatedEsrOhmsAt(frequencyMegahertz)}
      }

      // Only calculate in range fmin to fmax
      // if(frequencyMegahertz < fminMegahertz || frequencyMegahertz > fmaxMegahertz) {
      //   return {frequencyMegahertz: frequencyMegahertz, esrOhms: NaN}
      // }
      if(frequencyMegahertz < fminMegahertz) {
        return {frequencyMegahertz: frequencyMegahertz, esrOhms: NaN}
      }
      // Calculate less than fa/fc using resonant lines
      else if(frequencyMegahertz < faMegahertz && frequencyMegahertz < fcMegahertz) {
        let extrapolated = this.extrapolatedEsrOhmsAt(frequencyMegahertz)
        return {frequencyMegahertz: frequencyMegahertz, esrOhms: extrapolated}
      }
      // When fa < fc, use resonant lines
      else if(faMegahertz < fcMegahertz && frequencyMegahertz >= faMegahertz && frequencyMegahertz <= fcMegahertz) {
        return {frequencyMegahertz: frequencyMegahertz, esrOhms: this.extrapolatedEsrOhmsAt(frequencyMegahertz)}
      }
      // From fa/fc to fmax, use combined calculations
      else {
        let offsetAtFc = this.offsetAtFc
        let esrAtFc = this.esrOhmsAtFc

        let extrapolatedEsr = math.eval(`esrAtFc * (frequencyMegahertz / fcMegahertz) ^ logSlope`, {
          esrAtFc: esrAtFc, fcMegahertz: fcMegahertz, logSlope: constants.logSlope,
          frequencyMegahertz: math.bignumber(frequencyMegahertz)
        })

        let y = this.esrFromS11OhmsAt(frequencyMegahertz)
        let y_plus_offset = math.eval(`y + offsetAtFc`, { y: y, offsetAtFc: offsetAtFc })

        if(!isNaN(y) && y_plus_offset > extrapolatedEsr) {
          return {frequencyMegahertz: frequencyMegahertz, esrOhms: y_plus_offset}
        }
        else {
          return {frequencyMegahertz: frequencyMegahertz, esrOhms: extrapolatedEsr}
        }
      }
    })

    return completeAdjustedEsrOhms
  }

  adjustedEsrOhmsAt(frequencyMegahertz) {
    if(frequencyMegahertz === null) {
      return NaN
    }

    if(!this.sParameters.length) {
      return this.extrapolatedEsrOhmsAt(frequencyMegahertz)
    }

    let faMegahertz = this.faMegahertz
    let fcMegahertz = this.fcMegahertz
    let fmaxMegahertz= this.fmaxMegahertz
    let fminMegahertz = this.frequencyMinMegahertz


    // Only calculate in range fmin to fmax
    if(frequencyMegahertz < fminMegahertz || frequencyMegahertz > fmaxMegahertz) {
      return NaN
    }
    // Calculate less than fa/fc using resonant lines
    else if(frequencyMegahertz < faMegahertz && frequencyMegahertz < fcMegahertz) {
      return this.extrapolatedEsrOhmsAt(frequencyMegahertz)
    }
    // When fa < fc, use resonant lines
    else if(faMegahertz < fcMegahertz && frequencyMegahertz >= faMegahertz && frequencyMegahertz <= fcMegahertz) {
      return this.extrapolatedEsrOhmsAt(frequencyMegahertz)
    }
    // From fa/fc to fmax, use combined calculations
    else {
      let offsetAtFc = this.offsetAtFc
      let esrAtFc = this.esrOhmsAtFc

      let extrapolatedEsr = math.eval(`esrAtFc * (frequencyMegahertz / fcMegahertz) ^ logSlope`, {
        esrAtFc: esrAtFc, fcMegahertz: fcMegahertz, logSlope: constants.logSlope,
        frequencyMegahertz: math.bignumber(frequencyMegahertz)
      })

      let y = this.esrFromS11OhmsAt(frequencyMegahertz)
      let y_plus_offset = math.eval(`y + offsetAtFc`, { y: y, offsetAtFc: offsetAtFc })

      if(!isNaN(y) && y_plus_offset > extrapolatedEsr) {
        return y_plus_offset
      }
      else {
        return extrapolatedEsr
      }
    }
  }

  magS11DecibelsAt(frequencyMegahertz) {
    let closestSParameters = this.closestSParametersAt(frequencyMegahertz)
    let previous = closestSParameters[0]
    let next = closestSParameters[1]

    if(previous === undefined || next === undefined) {
      return NaN
    }

    let previousMagS11Decibels = math.eval(`20 * log10(previousMagS11)`, { previousMagS11: math.bignumber(previous.magS11)})
    let nextMagS11Decibels = math.eval(`20 * log10(nextMagS11)`, { nextMagS11: math.bignumber(next.magS11)})

    let result = math.eval(
      `previousMagS11Decibels +
       (frequencyMegahertz - previousFrequencyMegahertz) /
       (nextFrequencyMegahertz - previousFrequencyMegahertz) *
       (nextMagS11Decibels - previousMagS11Decibels)`, {
        frequencyMegahertz: math.bignumber(frequencyMegahertz),
        previousMagS11Decibels: previousMagS11Decibels,
        previousFrequencyMegahertz: math.bignumber(previous.frequencyMegahertz),
        nextFrequencyMegahertz: math.bignumber(next.frequencyMegahertz),
        nextMagS11Decibels: nextMagS11Decibels
      })

    return math.bignumber(result)
  }

  angS11DegreesAt(frequencyMegahertz) {
    let closestSParameters = this.closestSParametersAt(frequencyMegahertz)
    let previous = closestSParameters[0]
    let next = closestSParameters[1]

    if(previous === undefined || next === undefined) {
      return NaN
    }

    let result = math.eval(
      `previousAngS11Decibels +
       (frequencyMegahertz - previousFrequencyMegahertz) /
       (nextFrequencyMegahertz - previousFrequencyMegahertz) *
       (nextAngS11Decibels - previousAngS11Decibels)`, {
        frequencyMegahertz: math.bignumber(frequencyMegahertz),
        previousAngS11Decibels: math.bignumber(previous.angS11),
        previousFrequencyMegahertz: math.bignumber(previous.frequencyMegahertz),
        nextFrequencyMegahertz: math.bignumber(next.frequencyMegahertz),
        nextAngS11Decibels: math.bignumber(next.angS11)
      })

    return math.bignumber(result)
  }

  magS21DecibelsAt(frequencyMegahertz) {
    let closestSParameters = this.closestSParametersAt(frequencyMegahertz)
    let previous = closestSParameters[0]
    let next = closestSParameters[1]

    if(previous === undefined || next === undefined) {
      return NaN
    }

    let previousMagS21Decibels = math.eval(`20 * log10(previousMagS21)`, { previousMagS21: math.bignumber(previous.magS21)})
    let nextMagS21Decibels = math.eval(`20 * log10(nextMagS21)`, { nextMagS21: math.bignumber(next.magS21)})

    let result = math.eval(
      `previousMagS21Decibels +
       (frequencyMegahertz - previousFrequencyMegahertz) /
       (nextFrequencyMegahertz - previousFrequencyMegahertz) *
       (nextMagS21Decibels - previousMagS21Decibels)`
      , {
      frequencyMegahertz: math.bignumber(frequencyMegahertz),
      previousMagS21Decibels: previousMagS21Decibels,
      previousFrequencyMegahertz: math.bignumber(previous.frequencyMegahertz),
      nextFrequencyMegahertz: math.bignumber(next.frequencyMegahertz),
      nextMagS21Decibels: nextMagS21Decibels
    })
    return math.bignumber(result)
  }

  angS21DegreesAt(frequencyMegahertz) {
    let closestSParameters = this.closestSParametersAt(frequencyMegahertz)
    let previous = closestSParameters[0]
    let next = closestSParameters[1]

    if(previous === undefined || next === undefined) {
      return NaN
    }

    let result = math.eval(
      `previousAngS21Decibels +
       (frequencyMegahertz - previousFrequencyMegahertz) /
       (nextFrequencyMegahertz - previousFrequencyMegahertz) *
       (nextAngS21Decibels - previousAngS21Decibels)`, {
        frequencyMegahertz: math.bignumber(frequencyMegahertz),
        previousAngS21Decibels: math.bignumber(previous.angS21),
        previousFrequencyMegahertz: math.bignumber(previous.frequencyMegahertz),
        nextFrequencyMegahertz: math.bignumber(next.frequencyMegahertz),
        nextAngS21Decibels: math.bignumber(next.angS21)
      })

    return math.bignumber(result)
  }

  ceffPicofaradsAt(frequencyMegahertz) {
    if(!this.capacitancePicofarads) {
      return NaN
    }

    let result = math.eval(`capacitancePicofarads * 1 / ( 1 - (frequencyMegahertz / srfMegahertz)^2 )`, {
      capacitancePicofarads: this.capacitancePicofarads,
      srfMegahertz: this.srfMegahertz,
      frequencyMegahertz: math.bignumber(frequencyMegahertz)})
    
    if(result < 0) {
      return NaN
    }

    return math.bignumber(result)
  }

  xcOhmsAt(frequencyMegahertz) {
    if(frequencyMegahertz === null || !this.capacitancePicofarads) {
      return NaN
    }

    let result = math.eval(`1 / (6.28 * frequencyMegahertz * (10^6) * capacitancePicofarads * (10^-12))`, {
      capacitancePicofarads: this.capacitancePicofarads,
      frequencyMegahertz: math.bignumber(frequencyMegahertz)})
    return math.bignumber(result)
  }

  xlOhmsAt(frequencyMegahertz) {
    if(frequencyMegahertz === null) {
      return NaN
    }

    let result = math.eval(`6.28 * frequencyMegahertz * (10^6) * eslNanohenries * (10^-9)`, {
      frequencyMegahertz: math.bignumber(frequencyMegahertz),
      eslNanohenries: this.eslNanohenries})
    return math.bignumber(result)
  }

  zPhaseDegreesAt(frequencyMegahertz) {
    if(frequencyMegahertz === null || !this.capacitancePicofarads) {
      return NaN
    }

    let esrOhms = this.extrapolatedAdjustedEsrOhmsAt(frequencyMegahertz)
    let xcOhms = this.xcOhmsAt(frequencyMegahertz)
    let xlOhms = this.xlOhmsAt(frequencyMegahertz)

    let result = math.eval(`atan((xlOhms - xcOhms)/esrOhms) * 180 / pi`, {
      xcOhms: xcOhms, xlOhms: xlOhms, esrOhms: esrOhms})
    return math.bignumber(result)
  }

  zOhmsAt(frequencyMegahertz) {
    let esrOhms = this.extrapolatedAdjustedEsrOhmsAt(frequencyMegahertz)
    let xcOhms = this.xcOhmsAt(frequencyMegahertz)
    let xlOhms = this.xlOhmsAt(frequencyMegahertz)

    if(!esrOhms || !this.capacitancePicofarads || !frequencyMegahertz) {
      return NaN
    }

    let result = math.eval(`((esrOhms^2) + (xcOhms - xlOhms)^2)^0.5`, {
      xcOhms: xcOhms, xlOhms: xlOhms, esrOhms: esrOhms})
    return math.bignumber(result)
  }

  qAt(frequencyMegahertz) {
    let esrOhms = this.extrapolatedAdjustedEsrOhmsAt(frequencyMegahertz)
    let xcOhms = this.xcOhmsAt(frequencyMegahertz)
    let xlOhms = this.xlOhmsAt(frequencyMegahertz)

    let result = math.eval(
      `abs(xcOhms - xlOhms) / esrOhms`, {esrOhms: esrOhms, xcOhms: xcOhms, xlOhms: xlOhms }
    )

    if(result <= .001) {
      return .001
    }
    // if(result <= 0.1) {
    //   return math.bignumber(0.101)
    // }

    return math.bignumber(result)
  }

  srfGigahertzAt(capacitance) {
    let closestCapacitanceValues = this.closestCapacitanceAt(capacitance)
    let previous = closestCapacitanceValues[0]
    let next = closestCapacitanceValues[1]

    if(previous === undefined || next === undefined) {
      return NaN
    }

    if(previous === next) {
      return math.bignumber(previous.srfMegahertz / 1000)
    }


    let result = math.eval(
        `previousSrfGigahertz +
        (capacitance - previousCapacitance) /
        (nextCapacitance - previousCapacitance) *
        (nextSrfGigahertz - previousSrfGigahertz)`, {
      capacitance: math.bignumber(capacitance),
      previousCapacitance: math.bignumber(previous.capacitancePicofarads),
      nextCapacitance: math.bignumber(next.capacitancePicofarads),
      previousSrfGigahertz: math.bignumber(previous.srfMegahertz / 1000),
      nextSrfGigahertz: math.bignumber(next.srfMegahertz / 1000)})

    return math.bignumber(result)
  }

  srfvGigahertzAt(capacitance) {
    let closestCapacitanceValues = this.closestCapacitanceAt(capacitance)
    let previous = closestCapacitanceValues[0]
    let next = closestCapacitanceValues[1]

    if(previous === undefined || next === undefined) {
      return NaN
    }

    if(previous === next) {
      return math.bignumber(previous.srfvMegahertz / 1000)
    }

    let result = math.eval(
        `previousSrfvGigahertz +
        (capacitance - previousCapacitance) /
        (nextCapacitance - previousCapacitance) *
        (nextSrfvGigahertz - previousSrfvGigahertz)`, {
      capacitance: math.bignumber(capacitance),
      previousCapacitance: math.bignumber(previous.capacitancePicofarads),
      nextCapacitance: math.bignumber(next.capacitancePicofarads),
      previousSrfvGigahertz: math.bignumber(previous.srfvMegahertz / 1000),
      nextSrfvGigahertz: math.bignumber(next.srfvMegahertz / 1000)})

    return math.bignumber(result)
  }

  prfhGigahertzAt(capacitance) {
    let closestCapacitanceValues = this.closestCapacitanceAt(capacitance)
    let previous = closestCapacitanceValues[0]
    let next = closestCapacitanceValues[1]
  
    // debugger
    if(previous === undefined || next === undefined) {
      return NaN
    }

    if(previous.capacitancePicofarads === capacitance && previous.prfhMegahertz != 0) {
      return math.bignumber(previous.prfhMegahertz / 1000)
    }

    if(next.capacitancePicofarads === capacitance && next.prfhMegahertz != 0) {
      return math.bignumber(next.prfhMegahertz / 1000)
    }

    if(previous.prfhMegahertz == 0 || next.prfhMegahertz == 0) {
      return NaN
    }

    if(previous === next) {
      return math.bignumber(previous.prfhMegahertz / 1000)
    }

    let result = math.eval(
        `previousPrfhGigahertz +
        (capacitance - previousCapacitance) /
        (nextCapacitance - previousCapacitance) *
        (nextPrfhGigahertz - previousPrfhGigahertz)`, {
      capacitance: math.bignumber(capacitance),
      previousCapacitance: math.bignumber(previous.capacitancePicofarads),
      nextCapacitance: math.bignumber(next.capacitancePicofarads),
      previousPrfhGigahertz: math.bignumber(previous.prfhMegahertz / 1000),
      nextPrfhGigahertz: math.bignumber(next.prfhMegahertz / 1000)})

    return math.bignumber(result)

  }

  prfvGigahertzAt(capacitance) {
    let closestCapacitanceValues = this.closestCapacitanceAt(capacitance)
    let previous = closestCapacitanceValues[0]
    let next = closestCapacitanceValues[1]

    if(previous === undefined || next === undefined) {
      return NaN
    }

    if(previous.capacitancePicofarads === capacitance && previous.prfvMegahertz != 0) {
      return math.bignumber(previous.prfvMegahertz / 1000)
    }

    if(next.capacitancePicofarads === capacitance && next.prfvMegahertz != 0) {
      return math.bignumber(next.prfvMegahertz / 1000)
    }

    if(previous.prfvMegahertz == 0 || next.prfvMegahertz == 0) {
      return NaN
    }

    if(previous === next) {
      return math.bignumber(previous.prfvMegahertz / 1000)
    }

    let result = math.eval(
        `previousPrfvGigahertz +
        (capacitance - previousCapacitance) /
        (nextCapacitance - previousCapacitance) *
        (nextPrfvGigahertz - previousPrfvGigahertz)`, {
      capacitance: math.bignumber(capacitance),
      previousCapacitance: math.bignumber(previous.capacitancePicofarads),
      nextCapacitance: math.bignumber(next.capacitancePicofarads),
      previousPrfvGigahertz: math.bignumber(previous.prfvMegahertz / 1000),
      nextPrfvGigahertz: math.bignumber(next.prfvMegahertz / 1000)})

    return math.bignumber(result)
  }

  lvmaxArmsAt(frequencyMegahertz, capacitancePicofarads) {
    if(!frequencyMegahertz || !capacitancePicofarads) {
      return NaN
    }

    let result = math.eval(`6.28 * frequencyMegahertz * (10^6) * capacitancePicofarads * (10^-12) * voltageValue / (2^0.5)`, {
      frequencyMegahertz: math.bignumber(frequencyMegahertz),
      capacitancePicofarads: math.bignumber(capacitancePicofarads),
      voltageValue: this.voltageValue})
    return math.bignumber(result)
  }

  lpmaxArmsAt(frequencyMegahertz) {
    if(!frequencyMegahertz) {
      return NaN
    }

    let esrOhms = this.extrapolatedAdjustedEsrOhmsAt(frequencyMegahertz)
    if(isNaN(esrOhms)) {
      return NaN
    }

    let pdissmaxWatt = math.eval(`(maxAllowedTemperatureCelcius - ambientTemperatureCelcius) / rthCelciusPerWatt`, {
      maxAllowedTemperatureCelcius: math.bignumber(this.maxAllowedTemperatureCelcius),
      ambientTemperatureCelcius: math.bignumber(this.ambientTemperatureCelcius),
      rthCelciusPerWatt: math.bignumber(this.rthCelciusPerWatt)})

    let result = math.eval(`(pdissmaxWatt / esrOhms)^0.5`, {
      pdissmaxWatt: pdissmaxWatt, esrOhms: esrOhms})

    return math.bignumber(result)
  }

  lmaxArmsAt(frequencyMegahertz, capacitancePicofarads) {
    let lvmax = this.lvmaxArmsAt(frequencyMegahertz, capacitancePicofarads)
    let lpmax = this.lpmaxArmsAt(frequencyMegahertz)

    if(calculator.maxAllowedTemperatureCelcius <= calculator.ambientTemperatureCelcius) {
      return 0
    }
    else if(isNaN(lpmax)) {
      return lvmax
    }
    else if(isNaN(lvmax)) {
      return lpmax
    }
    else {
      return math.min(lpmax, lvmax)
    }
  }

  linearInterpolate(args) {
    let { x: x, previousX: previousX, nextX: nextX, previousY: previousY, nextY: nextY} = args

    // Linear interpolation
    let result = math.eval(
      `previousY + (x - previousX) / (nextX - previousX) * (nextY - previousY)`, {
        x: math.bignumber(x), previousX: math.bignumber(previousX),
        nextX: math.bignumber(nextX), previousY: math.bignumber(previousY),
        nextY: math.bignumber(nextY)})
        return math.bignumber(result)
  }

  logarithmicInterpolate(args) {
    let { x: x, previousX: previousX, nextX: nextX, previousY: previousY, nextY: nextY} = args

    // Log interpolation
    if(x == previousX) {
      return previousY
    }
    else if(x == nextX) {
      return nextY
    }


    // When I use this method in Safari I get the wrong answer??
    // let a = math.eval(
    //   `(log10(nextY / previousY)) / (log10(nextX / previousX))`, {
    //     previousX: math.bignumber(previousX), nextX: math.bignumber(nextX),
    //     previousY: math.bignumber(previousY), nextY: math.bignumber(nextY)})
    
    let a = (Math.log10(nextY / previousY)) / (Math.log10(nextX / previousX))
    let result = math.eval(
      `previousY * ((x / previousX)^a)`, {
        x: math.bignumber(x), previousX: math.bignumber(previousX), 
        previousY: math.bignumber(previousY), a: math.bignumber(a)})
    return math.bignumber(result)
  }

  distributePointsLogarithmically(min, max) {
    if(min === undefined || max === undefined) {
      return null
    }

    let values = [0.001, 0.01, 0.1, 1, 10, 100, 1000, 10000, 100000]
    let tickStartIndex = null
    let tickEndIndex = null
    for(let i = 1; i < values.length; i++) {
      if(!tickStartIndex && values[i] >= min) {
        tickStartIndex = i - 1
      }
      if(!tickEndIndex && values[i] >= max) {
        tickEndIndex = i
      }
    }

    let ticks = values.slice(tickStartIndex, tickEndIndex + 1)
    return ticks
  }

  distributePointsLinearly(min, max) {
    if(min === undefined || max === undefined) {
      return null
    }

    let gradations = [0.01, 0.1, 0.25, 1, 5, 10, 25, 100, 1000, 10000]
    let gradation = null
    for(let i = gradations.length - 1; i >= 0; i--) {
      gradation = gradations[i]
      let possibleValues = []
      for(let j = -1000; j <= 1000; j++) {
        let possibleValue = j * gradation
        if(possibleValue >= min && possibleValue <= max) {
          possibleValues.push(possibleValue)
        }
      }

      if(possibleValues.length >= 5) {
        // Too many points?  Filter out every other element and try again
        while(possibleValues.length > 10) {
          possibleValues = possibleValues.filter((v, index) => index % 2 == 1)
        }

        while(min < possibleValues[0]) {
          possibleValues.unshift(possibleValues[0] - gradation)
        }
        while(max > possibleValues[possibleValues.length - 1]) {
          possibleValues.push(possibleValues[possibleValues.length - 1] + gradation)
        }

        return possibleValues
      }
    }
    return null
  }

  y1At(x) {
    switch(this.chartMode1) {
      case "srf":
        return this.srfGigahertzAt(x)
      case "srfv":
        return this.srfvGigahertzAt(x)
      case "prfh":
        return this.prfhGigahertzAt(x)
      case "prfv":
        return this.prfvGigahertzAt(x)
      case "s11_mag":
        return this.magS11DecibelsAt(x)
      case "s11_phase":
        return this.angS11DegreesAt(x)
      case "s21_mag":
        return this.magS21DecibelsAt(x)
      case "s21_phase":
        return this.angS21DegreesAt(x)
      case "adjusted_esr_lin":
        return this.extrapolatedAdjustedEsrOhmsAt(x) * 1000
      case "adjusted_esr_log":
        return this.extrapolatedAdjustedEsrOhmsAt(x) * 1000
      case "q_factor":
        return this.qAt(x)
      case "lmax_vs_freq":
        return this.lmaxArmsAt(x, this.capacitancePicofarads)
      default:
        return NaN
    }
  }

  y2At(x) {
    switch(this.chartMode2) {
      case "srf":
        return this.srfGigahertzAt(x)
      case "srfv":
        return this.srfvGigahertzAt(x)
      case "prfh":
        return this.prfhGigahertzAt(x)
      case "prfv":
        return this.prfvGigahertzAt(x)
      case "s11_mag":
        return this.magS11DecibelsAt(x)
      case "s11_phase":
        return this.angS11DegreesAt(x)
      case "s21_mag":
        return this.magS21DecibelsAt(x)
      case "s21_phase":
        return this.angS21DegreesAt(x)
      case "adjusted_esr_lin":
        return this.extrapolatedAdjustedEsrOhmsAt(x) * 1000
      case "adjusted_esr_log":
        return this.extrapolatedAdjustedEsrOhmsAt(x) * 1000
      case "q_factor":
        return this.qAt(x)
      case "lmax_vs_freq":
        return this.lmaxArmsAt(x, this.capacitancePicofarads)
      default:
        return NaN
    }
  }

  toNumber(n) {
    if(n === null) {
      return NaN;
    }
    else if(typeof(n) === "object" && "toNumber" in n) {
      return n.toNumber();
    }
    else if(typeof(n) === "number") {
      return n;
    }
    else {
      return NaN;
    }
  }

  resetSelectors() {
    runInAction("resetSelectors()", () => {
      this.toleranceCode = this.availableToleranceCodes[0].value
      this.voltageCode = this.availableVoltageCodes[0].value
      this.terminationCode = this.availableTerminationCodes[0].value
      this.markingCode = this.availableMarkingCodes[0].value
      this.packagingCode = this.availablePackagingCodes[0].value

      if(this.chartMode1Meta.xAxis === "capacitance") {
        this.startX = store.minCapacitancePicofarads
        this.minX = store.minCapacitancePicofarads
        this.endX = store.maxCapacitancePicofarads
        this.maxX = store.maxCapacitancePicofarads
        this.activeChartFrequency = store.minCapacitancePicofarads
      }
      else if(this.chartMode1Meta.xAxis === "frequency") {
        this.startX = store.frequencyMinMegahertz
        this.minX = store.frequencyMinMegahertz
        this.endX = this.fmaxMegahertz || store.frequencyMaxMegahertz
        this.maxX = this.fmaxMegahertz || store.frequencyMaxMegahertz
        this.activeChartFrequency = store.frequencyMinMegahertz
      }
      if(this.frequencyMegahertz < store.frequencyMinMegahertz) {
        this.frequencyMegahertz = store.frequencyMinMegahertz
      }
      else if(this.frequencyMegahertz > this.fmaxMegahertz) {
        this.frequencyMegahertz = this.fmaxMegahertz || store.frequencyMaxMegahertz
      }
    })
  }
}
const calculator = new ObservableDataStore();



export default calculator;
