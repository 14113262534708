import React from 'react';
import PropTypes from 'prop-types'
import {  Row, Col, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap'
import ReactBootstrapSlider from 'react-bootstrap-slider';
import calculator from './calculator.js'
import loginStore from './loginStore.js'

class UIRange extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value,
      validationError: null,
      timeout: null
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({value: nextProps.value})
  }

  requireLoggedInUserIfNeeded(callback) {
    if(this.props.requireLoggedInUser) {
      loginStore.requireLoggedInUser(() => callback && callback())
    }
    else {
      callback()
    }
  }

  handleChange(e) {
    this.requireLoggedInUserIfNeeded(() => {
      let validationError = this.validateNumericality(e.target.value)
        || (this.props.validateOnChange && this.props.validateOnChange(e))
      this.setState({validationError: validationError, value: e.target.value})
      if(!validationError) {
        if(this.props.onChange) {
          this.props.onChange(e)
        }
        if(this.props.onChangeDelayed) {
          calculator.calculating = true
          let value = e.target.value
          let timeout = setTimeout( () => {
            this.props.onChangeDelayed(value)
            calculator.calculating = false
          }, 650)

          if(this.state.timeout) {
            clearTimeout(this.state.timeout)
          }
          this.setState({timeout: timeout})
        }
      }
    })
  }

  validateNumericality(value) {
    return parseFloat(value) == value ? null : "Not a number"
  }

  render() {
    return (
      <FormGroup style={{marginLeft: 5, marginRight: 5}} validationState={this.state.validationError ? "error" : null}>
        <div className="ui-range">
          <div className="ui-range-label">
            <ControlLabel>{this.props.label}</ControlLabel>
          </div>
          <div className="ui-range-slider hide-on-pdf">
            <ReactBootstrapSlider
              value={parseFloat(this.state.value) || this.props.min }
              change={this.handleChange.bind(this)}
              min={this.props.min || 1}
              max={this.props.max || null}
              step={this.props.step}
            />
          </div>
          <div className="ui-range-input">
            <div className="input-group">
              <FormControl
                componentClass="input"
                value={this.state.value}
                onChange={this.handleChange.bind(this)}
                min={this.props.min}
                max={this.props.max}
                bsSize="sm"
              />
              <span className="input-group-addon">{this.props.unit}</span>
            </div>
            <HelpBlock>{this.state.validationError}</HelpBlock>
          </div>
        </div>
      </FormGroup>
    )
  }
}

UIRange.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onChangeDelayed: PropTypes.func,
  validateOnChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  unit: PropTypes.string,
  requireLoggedInUser: PropTypes.bool
}

export default UIRange;
