import React from 'react'
import { observer } from 'mobx-react'

import store from './store.js'

@observer
class Loader extends React.Component {
  render() {
    if(store.loading) {
      return (
        <div>
          <div style={styles.background}></div>
          <div style={styles.dialog}>Loading/Calculation in Progress</div>
        </div>
      )
    }
    else {
      return null
    }
  }
}

const styles = {
  background: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1105,
    background: "#ffffff",
    opacity: 0.4
  },
  dialog: {
    background: "#ffffff",
    border: "1px solid #cccccc",
    padding: "40px",
    margin: "auto",
    color: "#000",
    zIndex: 1110,
    fontSize: 32,
    position: "absolute",
    top: 100,
    left: "15%",
    width: "70%",
    textAlign: "center"
  }
}

export default Loader
