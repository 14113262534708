import 'whatwg-fetch'
import { observable, computed, action, runInAction } from 'mobx'

class ObservableDataStore {
  @observable part
  @observable showOrderModal = false
  @observable loading = false

  @action closeModal() {
    this.part = null
    this.showOrderModal = false
  }

  @action submitRequest() {
    this.loading = true

    fetch(`/s_parameter_requests.json`, {
      credentials: "same-origin",
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ part_number: this.part })
    })
    .then((response) => {
      this.loading = false
      this.showOrderModal = false
      this.part = null
    })
  }
}

const sParameterOrder = new ObservableDataStore();

export default sParameterOrder;
