import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'

@observer
class LMaxDisplay extends React.Component {

  render() {
    return (
      <UIStatic
        label="lmax"
        value={calculator.lmaxArms.toFixed(2)}
        unit="A rms"
      />
    )
  }
}

export default LMaxDisplay;
