import React from 'react'
import { observer } from 'mobx-react'
import calculator from './calculator.js'
import UINumber from './UINumber.js'
import constants from './constants'

@observer
class AmbientTemperatureControl extends React.Component {
  render() {
    return (
      <UINumber
        label="Ambient Temp"
        value={calculator.ambientTemperatureCelcius}
        onChange={(e) => calculator.ambientTemperatureCelcius = parseFloat(e.target.value)}
        validateOnChange={e => {
          const val = parseFloat(e.target.value)
          if(val < constants.minAllowedTemperatureCelcius) {
            return `Temp must be between ${constants.minAllowedTemperatureCelcius}˚C and +${constants.maxAllowedTemperatureCelcius}˚C`
          }
          else if(val > constants.maxAllowedTemperatureCelcius) {
            return `Temp must be between ${constants.minAllowedTemperatureCelcius}˚C and +${constants.maxAllowedTemperatureCelcius}˚C`
          }
          else if(val >= calculator.maxAllowedTemperatureCelcius) {
            return `Ambient temp must be less than max temp`
          }
        }}
        bsSize="sm"
        unit="°C"
        requireLoggedInUser={true}
      />
    )
  }
}

export default AmbientTemperatureControl;
