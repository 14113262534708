import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UISelect from './UISelect.js'

@observer
class MarkingCodeControl extends React.Component {
  render() {
    return (
      <UISelect
        label="Marking"
        value={calculator.markingCode}
        onChange={(e) => calculator.markingCode = e.target.value}
        options={calculator.availableMarkingCodes}
        bsSize="sm"
        requireLoggedInUser={true}
      />
    )
  }
}

export default MarkingCodeControl;
