import React from 'react';
import { Row, Col, ControlLabel } from 'react-bootstrap'
import { observer } from 'mobx-react';
import UINumber from './UINumber.js'
import calculator from './calculator.js'
import HumanReadableNumber from '../../helpers/HumanReadableNumber'

@observer
class ChartResult extends React.Component {
  changeHandler(e) {
    if(parseFloat(e.target.value)) {
      calculator.activeChartFrequency = parseFloat(e.target.value)
    }
  }

  renderChart1Value() {
    const y1 = calculator.y1At(calculator.activeChartFrequency)
    const { chartMode1, sParameters } = calculator

    if((chartMode1 === "prfh" || chartMode1 === "prfv") && !y1 && sParameters.length) {
      return ">10"
    }
    else {
      return (
        <HumanReadableNumber value={y1} />
      )
    }
  }

  renderChart2Value() {
    const y2 = calculator.y2At(calculator.activeChartFrequency)
    const { chartMode2, sParameters } = calculator

    if((chartMode2 === "prfh" || chartMode2 === "prfv") && !y2 && sParameters.length) {
      return ">10"
    }
    else {
      return (
        <HumanReadableNumber value={y2} />
      )
    }
  }

  render() {
    const xLabel = `${calculator.chartXAxisLabel} (${calculator.chartMode1Meta.xAxisScale})`

    return (
      <div className="panel panel-primary">
        <div className="panel-body">
          <Row>
            <UINumber
              label={xLabel}
              labelStyle={{color: "#333"}}
              value={calculator.activeChartFrequency}
              onChange={e => calculator.activeChartFrequency = e.target.value}
              requireLoggedInUser={true}
              bsSize="sm"
              unit={calculator.chartXAxisUnit}
              validateOnChange={(e) => {
                let val = parseFloat(e.target.value)
                if(val < calculator.startX) {
                  return `Must be at least ${calculator.startX}`
                }
                else if(val > calculator.endX) {
                  return `Must be less than or equal to ${calculator.endX}`
                }
              }}
            />
          </Row>
          { (calculator.chartMode1 && calculator.chartMode1) != "none" &&
            <Row>
              <Col xs={6} className="text-right">
                <ControlLabel style={{color: "#333"}}>{ calculator.chartYAxis1Label }</ControlLabel>
              </Col>
              <Col xs={6}>
                <span style={{color: "#333"}}> { this.renderChart1Value() } </span>
              </Col>
            </Row>
          }
          { (calculator.chartMode2 && calculator.chartMode2) != "none" &&
            <Row>
              <Col xs={6} className="text-right">
                <ControlLabel style={{color: "#333"}}>{ calculator.chartYAxis2Label }</ControlLabel>
              </Col>
              <Col xs={6}>
                <span style={{color: "#333"}}>{ this.renderChart2Value() }</span>
              </Col>
            </Row>
          }
        </div>
      </div>
    )
  }
}

export default ChartResult
