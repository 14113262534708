import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'

@observer
class CeffDisplay extends React.Component {

  render() {
    return (
      <UIStatic
        label="Ceff"
        value={calculator.ceffPicofarads.toFixed(2)}
        unit="pF"
      />
    )
  }
}

export default CeffDisplay;
