import React from 'react'
import { observer } from 'mobx-react'
import { Form, Grid, Row, Col } from 'react-bootstrap'
import DevTools from 'mobx-react-devtools'
import Equalizer from 'react-equalizer'
import calculator from './calculator.js'
import store from './store.js'
import cart from './cart.js'
import loginStore from './loginStore'

import LoginModal from './LoginModal.js'
import Loader from './Loader.js'
import CapacitorSelectionPanel from './CapacitorSelectionPanel.js'
import RFParametersPanel from './RFParametersPanel.js'
import ChartDataSourceControl from './ChartDataSourceControl.js'
import ChartDisplay from './ChartDisplay.js'
import ChartResult from './ChartResult.js'


@observer
class MLCSoft extends React.Component {
  constructor(props) {
    super(props)

    loginStore.userId           = this.props.userId
    store.defaultSeriesCode     = this.props.defaultSeriesCode
    store.defaultCapacitance    = this.props.defaultCapacitance
    calculator.seriesCodes      = this.props.seriesCodes
    calculator.capacitorCodes   = this.props.capacitorCodes
    calculator.markingCodes     = this.props.markingCodes
    calculator.markingCode      = this.props.defaultMarkingCode
    calculator.packagingCodes   = this.props.packagingCodes
    calculator.packagingCode    = this.props.defaultPackagingCode
    calculator.toleranceCodes   = this.props.toleranceCodes
    calculator.toleranceCode    = this.props.defaultToleranceCode
    calculator.terminationCodes = this.props.terminationCodes
    calculator.terminationCode  = this.props.defaultTerminationCode
    calculator.voltageCodes     = this.props.voltageCodes
    calculator.voltageCode      = this.props.defaultVoltageCode
    cart.fname                  = this.props.firstName
    cart.lname                  = this.props.lastName
    cart.email                  = this.props.email
    cart.company                = this.props.company
    cart.address1               = this.props.address1
    cart.address2               = this.props.address2
    cart.city                   = this.props.city
    cart.postalcode             = this.props.postalcode
    cart.country                = this.props.country
    cart.phone                  = this.props.phone
    cart.ext                    = this.props.ext
    cart.fax                    = this.props.fax
    cart.serverIp               = this.props.serverIp
  }

  render() {
    return (
      <div className="MLCSoft" style={{marginTop: 30}}>
        {this.props.env !== "production" && 
          <DevTools />
        }
        <div style={{position: "relative"}}>
          <Loader />
          <Grid fluid={true} className="dark">
            <Row>
              <Col sm={5}>
                <CapacitorSelectionPanel />
                <RFParametersPanel />
              </Col>
              <Col sm={7}>
                <Row>
                  <Col md={6}>
                    <ChartResult />
                  </Col>
                </Row>
                <div className="panel panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title" style={{fontWeight: 'normal'}}>RF Charts</h3>
                  </div>
                  <div className="panel-body">
                    { store.loading ||
                      <div>
                        <ChartDisplay />
                        <hr />
                        <ChartDataSourceControl />
                      </div>
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
        <LoginModal />
      </div>
    )
  }
}

export default MLCSoft
