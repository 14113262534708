import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap'
import loginStore from './loginStore'

class UINumber extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value,
      validationError: null,
      timeout: null
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({value: nextProps.value})
  }

  requireLoggedInUserIfNeeded(callback) {
    if(this.props.requireLoggedInUser) {
      loginStore.requireLoggedInUser(() => callback && callback())
    }
    else {
      callback()
    }
  }

  handleChange(e) {
    this.requireLoggedInUserIfNeeded(() => {
      let validationError = this.validateNumericality(e.target.value)
        || (this.props.validateOnChange && this.props.validateOnChange(e))
      this.setState({validationError: validationError, value: e.target.value})
      if(!validationError) {
        this.setState({value: e.target.value})

        if(this.props.onChange) {
          this.props.onChange(e)
        }
        if(this.props.onChangeDelayed) {
          let value = e.target.value
          let timeout = setTimeout(() => this.props.onChangeDelayed(value), 750)
          if(this.state.timeout) {
            clearTimeout(this.state.timeout)
          }
          this.setState({timeout: timeout})
        }
      }
    })
  }

  validateNumericality(value) {
    return parseFloat(value) == value ? null : "Not a number"
  }

  render() {
    return (
      <FormGroup validationState={this.state.validationError ? "error" : null}>
        <Col xs={6}>
          <div className="text-right">
            <ControlLabel style={this.props.labelStyle}>{this.props.label}</ControlLabel>
          </div>
        </Col>
        <Col xs={6}>
          <div className="input-group">
            <FormControl
              componentClass="input"
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
              bsSize={this.props.bsSize}
            />
            { this.props.unit && 
              <span className="input-group-addon" id="basic-addon1">{this.props.unit}</span>
            }
          </div>
          <HelpBlock>{this.state.validationError}</HelpBlock>
        </Col>
      </FormGroup>
    )
  }
}

UINumber.propTypes = {
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onChangeDelayed: PropTypes.func,
  validateOnChange: PropTypes.func,
  bsSize: PropTypes.string,
  unit: PropTypes.string,
  requireLoggedInUser: PropTypes.bool
}

export default UINumber;
