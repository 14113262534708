import 'whatwg-fetch'
import { observable, computed, action, runInAction } from 'mobx'
import constants from './constants.js'
var uniq = require('lodash/uniq');

class ObservableDataStore {
  constructor() {
    this.autoloadEsrEstimates = true
    this.autoloadSParameters = false
    this.defaultSeriesCode = null
    this.defaultCapacitance = null
  }

  @observable __capacitancePicofarads
  @observable __seriesName
  @observable loading = false
  @observable __data = {}

  set capacitancePicofarads(value) {
    this.__capacitancePicofarads = value
  }

  @computed get capacitancePicofarads() {
    return this.__capacitancePicofarads
  }

  set seriesName(value) {
    this.capacitancePicofarads = Object.keys(this.data[value]).sort((a, b) => { return a - b })[0]
    this.__seriesName = value
  }

  @computed get seriesName() {
    return this.__seriesName
  }

  @computed get normalizedCapacitance() {
    if(!this.capacitancePicofarads) {
      return null
    }
    return parseFloat(this.capacitancePicofarads).toFixed(1)
  }

  @computed get series() {
    if(!this.__data || !this.seriesName) {
      return null
    }
    return this.__data[this.seriesName]
  }

  @computed get capacitor() {
    if(!this.series || !this.normalizedCapacitance) {
      return null
    }
    return this.series[this.normalizedCapacitance]
  }

  @computed get seriesNames() {
    let seriesNames = Object.keys(this.__data)

    if(!seriesNames.length) {
      setTimeout(() => {
        runInAction("seriesNames", () => {
          // console.log("fetching series codes")
          this.loading = true
          fetch(`/series.json`, {credentials: "same-origin"})
            .then((response) => { return response.json() })
            .then((json) => {
              this.__data = json.series
              this.loading = false
              // Set a default value if needed
              if(!this.seriesName) {
                this.__seriesName = this.defaultSeriesCode || Object.keys(json.series).sort()[0]
              }
              if(!this.capacitancePicofarads) {
                this.capacitancePicofarads = this.defaultCapacitance || Object.keys(json.series[this.seriesName]).sort((a, b) => { return a - b } )[0]
              }
            })
            .then(() => {
              if(this.afterFirstLoad && !this.firstLoadComplete) {
                this.afterFirstLoad()
                this.firstLoadComplete = true
              }
            })
        })
      }, 0)
    }

    return seriesNames.sort()
  }

  @computed get capacitors() {
    if(!this.seriesName || !this.data) {
      return []
    }
    let keys = Object.keys(this.data[this.seriesName])
    let values = keys.map((k) => this.data[this.seriesName][k])
      .sort((a, b) => { return a.capacitancePicofarads - b.capacitancePicofarads })
    return values
  }

  @computed get availableCapacitorValues() {
    if(!this.seriesName) {
      return []
    }

    let availableCapacitorValues = this.capacitors
      .map((capacitor) => capacitor["capacitancePicofarads"])
      .map((capacitor) => capacitor.toFixed(1))
    return availableCapacitorValues
  }

  @computed get availableToleranceCodes() {
    return this.capacitor ? this.capacitor.toleranceCodes : []
  }

  @computed get availableVoltageCodes() {
    return this.capacitor ? this.capacitor.voltageCodes : []
  }

  @computed get availableTerminationCodes() {
    return this.capacitor ? this.capacitor.terminationCodes : []
  }

  @computed get availableMarkingCodes() {
    return this.capacitor ? this.capacitor.markingCodes : []
  }

  @computed get availablePackagingCodes() {
    return this.capacitor ? this.capacitor.packagingCodes : []
  }

  @computed get capacitorCode() {
    return this.capacitor ? this.capacitor.capacitorCode : null
  }

  @computed get srfMegahertz() {
    return this.capacitor ? this.capacitor.srfMegahertz : null
  }

  @computed get srfvMegahertz() {
    return this.capacitor ? this.capacitor.srfvMegahertz : null
  }

  @computed get frequencyMinMegahertz() {
    return this.capacitor ? Math.floor(this.capacitor.frequencyMinMegahertz) : null
  }

  @computed get frequencyMaxMegahertz() {
    return this.capacitor ? Math.floor(this.capacitor.frequencyMaxMegahertz) : null
  }

  @computed get rthCelciusPerWatt() {
    return this.capacitor ? this.capacitor.rthCelciusPerWatt : null
  }

  @computed get cadModelFileUrl() {
    return this.capacitor ? this.capacitor.cadModelFileUrl : null
  }

  @computed get sParameterFileUrl() {
    return this.capacitor ? this.capacitor.sParameterFileUrl : null
  }

  @computed get prfvMegahertz() {
    return this.capacitor ? this.capacitor.prfvMegahertz : null
  }

  @computed get prfhMegahertz() {
    return this.capacitor ? this.capacitor.prfhMegahertz : null
  }


  @computed get esrEstimates() {
    if(!this.capacitor) {
      return []
    }

    if(this.capacitor["esrEstimates"]) {
      return this.capacitor.esrEstimates
        .sort((a, b) => { return a.frequencyMegahertz - b.frequencyMegahertz })
    }
    else if(this.autoloadEsrEstimates) {
      this.loadCapacitorDetails()
    }

    return []
  }

  @computed get sParameters() {
    if(!this.capacitor) {
      return []
    }

    if(this.capacitor["sParameters"]) {
      return this.capacitor.sParameters
        .sort((a, b) => { return a.frequencyMegahertz - b.frequencyMegahertz })
    }
    else if(this.autoloadSParameters) {
      this.loadCapacitorDetails()
    }

    return []
  }

  @computed get data() {
    return this.__data
  }

  @computed get minCapacitancePicofarads() {
    if(!this.availableCapacitorValues.length) {
      return 0
    }

    return parseFloat(this.availableCapacitorValues[0])
  }

  @computed get maxCapacitancePicofarads() {
    if(!this.availableCapacitorValues.length) {
      return 0
    }
    return parseFloat(this.availableCapacitorValues.slice(-1)[0])
  }

  loadCapacitorDetails() {
    setTimeout(() => {
      runInAction("loadCapacitorDetails", () => {
        // console.log(`fetching capacitor details for ${this.seriesName}, ${this.capacitorCode}`)
        this.loading = true
        let unloadedCapacitorCodes = this.capacitors
          .filter((c) => { return !c["esrEstimates"]} )
          .map((c) => { return c.capacitorCode })
          .slice(0, constants.additionalCapacitorRecordsToFetch)
        unloadedCapacitorCodes.push(this.capacitorCode)

        let args = uniq(unloadedCapacitorCodes).map((i) => { return `capacitor_code[]=${i}` }).join("&")
        fetch(`/series/${this.seriesName}/capacitors.json?${args}`, {credentials: "same-origin"})
          .then((response) => { return response.json() })
          .then((json) => {
            Object.keys(json.capacitor).forEach((key) => {
              let normalizedKey = parseFloat(key).toFixed(1)
              // console.log(`loaded capacitor details for ${this.seriesName}, ${normalizedKey}`)
              this.__data[this.seriesName][normalizedKey] = json.capacitor[normalizedKey]
            })
            this.loading = false
          })
          .then(() => {
            if(this.afterFinalLoad && !this.finalLoadComplete) {
              this.afterFinalLoad()
              this.finalLoadComplete = true
            }
          })
      })
    }, 0)
  }

}
const store = new ObservableDataStore();



export default store;
