import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'

@observer
class QDisplay extends React.Component {

  render() {
    return (
      <UIStatic
        label="Q"
        value={calculator.q.toFixed(2)}
      />
    )
  }
}

export default QDisplay;
