import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UISelect from './UISelect.js'

@observer
class VoltageCodeControl extends React.Component {
  render() {
    return (
      <UISelect
        label="Voltage"
        value={calculator.voltageCode}
        onChange={(e) => calculator.voltageCode = e.target.value}
        options={calculator.availableVoltageCodes.map((option) => { return option })}
        bsSize="sm"
        requireLoggedInUser={true}
      />
    )
  }
}

export default VoltageCodeControl;
