import React from 'react';
import { Button, Glyphicon, Modal } from 'react-bootstrap'
import { observer } from 'mobx-react';
import cart from './cart.js'
import calculator from './calculator.js'

@observer
class OrderSampleButton extends React.Component {
  handleOrderSampleClick() {
    cart.addPart(calculator.partNumber)
    cart.showOrderModal = true
  }

  render() {
    return (
      <Button bsStyle="default" bsSize="small" block={this.props.block} onClick={this.handleOrderSampleClick.bind(this)}><Glyphicon glyph="envelope"></Glyphicon> Order Sample</Button>
    )
  }
}

export default OrderSampleButton
