var includes = require('lodash/includes')

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, ControlLabel, Radio } from 'react-bootstrap'
import constants from './constants.js'

class VerticalUIRadioGroup extends React.Component {
  render() {
    return (
      <FormGroup>
        <div style={{display: 'table', width: '100%', tableLayout: 'fixed'}}>
          <div style={{display: 'table-row'}}>
            <div style={{display: 'table-cell', textAlign: 'center' }}>&nbsp;</div>
            <div style={{display: 'table-cell', textAlign: 'center', color: constants.chartYAxis1Color }}>{this.props.row1label}</div>
            <div style={{display: 'table-cell', textAlign: 'center', color: constants.chartYAxis2Color }}>{this.props.row2label}</div>
          </div>

          {this.props.options.map((option) =>
            <div style={{display: 'table-row'}} key={option.key}>
              <div style={{display: 'table-cell', textAlign: 'center' }}>
                <ControlLabel style={{textAlign: 'center', fontWeight: 'normal', fontSize: '0.9em', display: 'block', width: '100%'}}>{option.label}</ControlLabel>
              </div>
              <div style={{display: 'table-cell', textAlign: 'center' }}>
                <Radio
                  name={`vertical-${this.props.name1}`}
                  checked={this.props.row1value === option.yAxis}
                  value={option.yAxis}
                  onChange={this.props.onRow1Change}
                  style={{textAlign: 'center'}}
                />
              </div>
              <div style={{display: 'table-cell', textAlign: 'center' }}>
                <Radio
                  name={`vertical-${this.props.name2}`}
                  checked={this.props.row2value === option.yAxis}
                  value={option.yAxis}
                  onChange={this.props.onRow2Change}
                  style={{textAlign: 'center'}}
                  disabled={includes(this.props.row2Disabledvalues, option.yAxis)}
                />
              </div>
            </div>
          )}
        </div>
      </FormGroup>
    )
  }
}

VerticalUIRadioGroup.propTypes = {
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string.isRequired,
  row1label: PropTypes.string.isRequired,
  row2label: PropTypes.string.isRequired,
  row1value: PropTypes.any,
  row2value: PropTypes.any,
  onRow1Change: PropTypes.func.isRequired,
  onRow2Change: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
}

export default VerticalUIRadioGroup;
