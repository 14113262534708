import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UISelect from './UISelect.js'

@observer
class CapacitorValueControl extends React.Component {
  render() {
    return (
      <UISelect
        label="Capacitance"
        value={calculator.capacitancePicofarads}
        onChange={ (e) => {
          calculator.calculating = true
          let value = e.target.value
          setTimeout(() => {
            calculator.capacitancePicofarads = value
            calculator.calculating = false
          }, 10)
        }}
        options={calculator.availableCapacitorValues}
        unit="pF"
        bsSize="sm"
        requireLoggedInUser={true}
      />
    )
  }
}

export default CapacitorValueControl;
