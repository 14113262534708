import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap'
import { observer } from 'mobx-react';
import cart from './cart.js'

@observer
class OrderSampleTextField extends React.Component {
  handleChange(e) {
    cart[this.props.name] = e.target.value
  }

  validationError() {
    let validationErrors = cart.validationErrors
    return validationErrors[this.props.name]
  }

  validationState() {
    return this.validationError() ? "error" : null
  }

  render() {
    return (
      <FormGroup validationState={this.validationState()}>
        <ControlLabel style={this.props.labelStyle}>{this.props.label}</ControlLabel>
            <FormControl
              componentClass="input"
              value={cart[this.props.name] || ''}
              onChange={this.handleChange.bind(this)}
              bsSize={this.props.bsSize}
              name={this.props.name}
              autoComplete={!this.props.disableAutoComplete}
            />
        <HelpBlock>{this.validationError()}</HelpBlock>
      </FormGroup>
    )
  }
}

OrderSampleTextField.propTypes = {
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  value: PropTypes.any,
  bsSize: PropTypes.string,
  name: PropTypes.string,
  disableAutoComplete: PropTypes.bool
}

export default OrderSampleTextField
