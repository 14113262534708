import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'

@observer
class ESLDisplay extends React.Component {
  render() {
    return (
      <UIStatic
        label="ESL"
        value={calculator.eslNanohenries.toFixed(2)}
        unit="nH"
      />
    )
  }
}

export default ESLDisplay;
