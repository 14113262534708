import { observable, computed, action, runInAction } from 'mobx'

class LoginStore {
  @observable userId
  @observable loginModalVisible = false

  @computed get loggedIn() {
    return this.userId ? true : false
  }

  @action showLoginModal() {
    this.loginModalVisible = true
  }

  @action hideLoginModal() {
    this.loginModalVisible = false
  }

  requireLoggedInUser(callback) {
    // Remove login requirement
    callback && callback()
    // if(this.loggedIn) {
    //   callback && callback()
    // }
    // else {
    //   this.showLoginModal()
    // }
  }
}
const loginStore = new LoginStore()
export default loginStore
