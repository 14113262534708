import React from 'react';
import PropTypes from 'prop-types'
import { Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import loginStore from './loginStore.js'

class UISelect extends React.Component {
  handleChange = (e) => {
    const { onChange, requireLoggedInUser } = this.props
    if(requireLoggedInUser) {
      loginStore.requireLoggedInUser(() => onChange(e))
    }
    else {
      onChange(e)
    }
  }

  render() {
    let inputGroupClasses = this.props.unit ? "input-group" : ""
    let options = this.props.options.map((option) => { return typeof(option) === 'object' ? option : {name: option, value: option}})
    return (
      <FormGroup>
        <Col xs={6}>
          <div className="text-right">
            <ControlLabel style={this.props.labelStyle}>{this.props.label}</ControlLabel>
          </div>
        </Col>
        <Col xs={6}>
          <div className={inputGroupClasses}>
            <FormControl
              bsSize={this.props.bsSize}
              componentClass="select"
              placeholder="Choose one..."
              value={this.props.value}
              onChange={this.handleChange}
              >
              {options.map((option) => 
                <option key={option.value} value={option.value}>{option.name}</option>
              )}
            </FormControl>
            { this.props.unit && 
              <span className="input-group-addon" id="basic-addon1">{this.props.unit}</span>
            }
          </div>
        </Col>
      </FormGroup>
    )
  }
}

UISelect.propTypes = {
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  bsSize: PropTypes.string,
  unit: PropTypes.string,
  requireLoggedInUser: PropTypes.bool
}

export default UISelect;
