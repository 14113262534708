import React from 'react'
import { observer } from 'mobx-react'
import calculator from './calculator.js'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Col, Row, Glyphicon } from 'react-bootstrap'

@observer
class PartNumberDisplay extends React.Component {
  constructor() {
    super()
    this.state = {copied: false, timeout: null}
  }
  render() {
    return (
      <Row>
        <Col xs={3}>
          <h3 className="panel-title" style={{textAlign: "right", fontWeight: "normal"}}>Part Number</h3>
        </Col>
        <Col xs={9}>
          <h3 className="panel-title">
            <span style={{fontWeight: "bold"}}>{calculator.partNumber}</span>
            &nbsp;
            <CopyToClipboard text={calculator.partNumber}
              onCopy={() => { 
                if(this.state.timeout) {
                  clearTimeout(this.state.timeout)
                }
                let timeout = setTimeout(() => { this.setState({copied: false})}, 3 * 1000)
                this.setState({copied: true, timeout: timeout} )
              }}
              >
              <Glyphicon glyph="copy" style={{color: "#aaa", cursor: "pointer"}}/>
            </CopyToClipboard>
            &nbsp;
            {this.state.copied &&
              <span style={{fontSize: "0.9em", color: "#aaa" }}>Copied to clipboard</span>
            }
          </h3>
        </Col>
      </Row>
    )
  }
}

export default PartNumberDisplay;
