import React from 'react';
import { observer } from 'mobx-react';
import constants from './constants.js'
import calculator from './calculator.js'
import { AnimationDecorator, ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, ReferenceLine } from 'recharts';
import HumanReadableNumber from '../../helpers/HumanReadableNumber'
import MediaBreakpoints from './MediaBreakpoints.js'

@observer
class ChartDisplay extends React.Component {
  constructor(props) {
    super(props)

    this.state = { windowWidth: window.innerWidth }
  }

  handleResize() {
    if(this.refs.responsiveContainer) {
      this.setState({ windowWidth: window.innerWidth })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this))
  }

  aspectRatio() {
    let aspectRatios = {
      xs: 1.1,
      sm: 1.6,
      md: 2,
      lg: 2.5
    }
    let breakpoint = MediaBreakpoints.current(this.state.windowWidth)
    let aspectRatio = aspectRatios[breakpoint]
    return aspectRatio
  }

  mouseMoveHandler(e) {
    if(e && e.activeLabel) {
      calculator.activeChartFrequency = e.activeLabel
    }
  }

  render() {
    if(!calculator.mergedChartData) {
      return null
    }

    const ChartLegend = () => (<div>{calculator.chartLegend}</div>)

    return (
      <div>
        { calculator.chartError && 
          <div style={{color: "#FF0000", textAlign: "center", fontSize: 14, padding: 50}}>{ calculator.chartError }</div>
        }
        { !calculator.chartError &&
          <div style={{position: 'relative'}}>
              <ResponsiveContainer width="100%" aspect={this.aspectRatio()} ref="responsiveContainer">
                <LineChart data={calculator.mergedChartData} margin={{top: 40, right: 20, bottom: 20, left: 20}} onMouseMove={this.mouseMoveHandler.bind(this)}>
                  <ReferenceLine alwaysShow={true} x={calculator.activeChartFrequency} stroke="#00812e" yAxisId="left" />
                  <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
                  <XAxis
                    label=""
                    dataKey="x"
                    unit={calculator.chartXAxisUnit}
                    domain={calculator.chartXAxisDomain}
                    scale={calculator.chartMode1Meta.xAxisScale}
                    type="number"
                    padding={{ right: 25 }}
                    ticks={calculator.chartXAxisTicks}
                    tickFormatter={(n) => HumanReadableNumber.formatted(n)}
                  />
                  {calculator.chartMode1 !== "none" && 
                    <YAxis
                      label={calculator.chartYAxis1Label}
                      yAxisId="left"
                      orientation="left"
                      stroke={constants.chartYAxis1Color}
                      padding={{ top: 20, bottom: 20 }}
                      domain={calculator.shareYAxisDomain ? calculator.chartSharedYAxisDomain : calculator.chartYAxis1Domain }
                      scale={calculator.chartMode1Meta.yAxisScale}
                      ticks={calculator.chartYAxis1Ticks}
                      tickFormatter={(n) => HumanReadableNumber.formatted(n)}
                    />
                  }
                  {calculator.chartMode2 !== "none" && 
                    <YAxis
                      label={calculator.chartYAxis2Label}
                      yAxisId="right"
                      orientation="right"
                      stroke={constants.chartYAxis2Color}
                      padding={{ top: 20, bottom: 20 }}
                      domain={calculator.shareYAxisDomain ? calculator.chartSharedYAxisDomain : calculator.chartYAxis2Domain }
                      scale={calculator.chartMode2Meta.yAxisScale}
                      ticks={calculator.chartYAxis2Ticks}
                      tickFormatter={(n) => HumanReadableNumber.formatted(n)}
                    />
                  }
                  <Legend wrapperStyle={{width: "100%", bottom: 10, textAlign: "center" }} content={<ChartLegend />} />
                  {calculator.chartMode1 !== "none" && 
                    <Line
                      name={calculator.lineTooltip}
                      yAxisId="left"
                      dataKey="y1"
                      stroke={constants.chartYAxis1Color}
                      dot={false}
                      animationDuration={750}
                      unit={calculator.chartMode1Meta.yAxisUnit}
                      strokeWidth={2}
                    />
                  }
                  {calculator.chartMode2 !== "none" && 
                    <Line
                      name={`${calculator.chartMode2Meta.label} (${calculator.chartMode2Meta.yAxisScale})`}
                      yAxisId="right"
                      dataKey="y2"
                      stroke={constants.chartYAxis2Color}
                      dot={false}
                      animationDuration={750}
                      unit={calculator.chartMode2Meta.yAxisUnit}
                      strokeWidth={2}
                    />
                  }
                </LineChart>
              </ResponsiveContainer>
            { (calculator.chartMode1 === "srf" || calculator.chartMode2 === "srf") &&
              <div>
                <div style={{width: 250, position: "absolute", bottom: 85, left: 105, fontSize: "0.9em"}} className="hidden-sm hidden-xs">
                  Note: The Series Resonant Frequency (SRF) is highly dependent on the substrate, pad/trace dimensions, and measurement method.
                </div>
                
                <div style={{fontSize: "0.9em"}} className="hidden-md hidden-lg">
                  Note: The Series Resonant Frequency (SRF) is highly dependent on the substrate, pad/trace dimensions, and measurement method.
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export default ChartDisplay;
