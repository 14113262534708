import React from 'react'
import { observer } from 'mobx-react'
import calculator from './calculator.js'
import UISelect from './UISelect.js'

@observer
class TerminationCodeControl extends React.Component {
  render() {
    return (
      <UISelect
        label="Termination"
        value={calculator.terminationCode}
        onChange={(e) => calculator.terminationCode = e.target.value}
        options={calculator.availableTerminationCodes}
        bsSize="sm"
        requireLoggedInUser={true}
      />
    )
  }
}

export default TerminationCodeControl;
