import React from 'react';

export default class HumanReadableNumber extends React.Component {
  static formatted(value, options = {}) {
    if(isNaN(value)) {
      return ''
    }
    else if(value.isBigNumber) {
      return value.toNumber().toLocaleString("en", {...options, maximumFractionDigits: 2})
    }
    else {
      return value.toLocaleString("en", {...options, maximumFractionDigits: 2})

    }
  }

  render() {
    const { value, options } = this.props
    return (
      <span>{HumanReadableNumber.formatted(value, options)}</span>
    )
  }
}
