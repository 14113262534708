import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import MLCSoft from '../components/MLCSoft'

document.addEventListener('DOMContentLoaded', () => {
  const nodes = document.getElementsByClassName('react-mlcsoft')
  for(let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    const data = JSON.parse(node.getAttribute('data'))

    ReactDOM.render(<MLCSoft {...data} />, node)
  }
})
