import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UISelect from './UISelect.js'

@observer
class SeriesNameControl extends React.Component {
  render() {
    return (
      <UISelect
        label="Package Size"
        value={calculator.seriesName}
        onChange={(e) => calculator.seriesName = e.target.value}
        options={calculator.availableSeriesNames}
        requireLoggedInUser={true}
        bsSize="sm"
      />
    )
  }
}

export default SeriesNameControl;
