export default class MediaBreakpoints {
  static current(width) {
    let xs = 0
    let sm = 768
    let md = 992
    let lg = 1200

    if(width < sm) {
      return 'xs'
    }
    else if(width < md) {
      return 'sm'
    }
    else if(width < lg) {
      return 'md'
    }
    else {
      return 'lg'
    }
  }
}
