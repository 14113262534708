import React from 'react'
import { observer } from 'mobx-react'
import calculator from './calculator.js'
import UIRange from './UIRange.js'

@observer
class FrequencyControl extends React.Component {
  render() {
    if(calculator.frequencyMegahertz === null) {
      return null
    }
    return (
      <UIRange
        label="Frequency"
        value={calculator.frequencyMegahertz}
        onChangeDelayed={(value) => calculator.frequencyMegahertz = parseFloat(value)}
        validateOnChange={(e) => {
          let val = parseFloat(e.target.value)
          if(val < calculator.frequencyMinMegahertz) {
            return `Must be at least ${calculator.frequencyMinMegahertz}`
          }
          else if(val > calculator.fmaxMegahertz) {
            return `Must be less then or equal to ${calculator.fmaxMegahertz}`
          }
        }}
        min={calculator.frequencyMinMegahertz}
        max={calculator.fmaxMegahertz}
        step={0.1}
        unit="MHz"
        requireLoggedInUser={true}
      />
    )
  }
}

export default FrequencyControl;
