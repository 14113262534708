import React from 'react';
import { Form, Grid, Row, Col } from 'react-bootstrap'

import SRFDisplay from './SRFDisplay.js'
import PRFHDisplay from './PRFHDisplay.js'
import PRFVDisplay from './PRFVDisplay.js'
import ESRDisplay from './ESRDisplay.js'
import QDisplay from './QDisplay.js'
import S11Display from './S11Display.js'
import S21Display from './S21Display.js'
import ZDisplay from './ZDisplay.js'
import ZPhaseDisplay from './ZPhaseDisplay.js'
import LmaxDisplay from './LmaxDisplay.js'
import CeffDisplay from './CeffDisplay.js'
import ESLDisplay from './ESLDisplay.js'
import FrequencyControl from './FrequencyControl.js'
import ResourceButtons from './ResourceButtons.js'
import AmbientTemperatureControl from './AmbientTemperatureControl.js'
import MaxAllowedTemperatureControl from './MaxAllowedTemperatureControl.js'


class RFParametersPanel extends React.Component {
  render() {
    return (
      <div className="panel panel-primary">
        <div className="panel-heading">
          <h3 className="panel-title" style={{fontWeight: "normal"}}>RF Parameters</h3>
        </div>
        <div className="panel-body">
          <Form horizontal onSubmit={(e) => e.preventDefault() }>
            <Row>
              <Col md={6}>
                <SRFDisplay />
                <PRFHDisplay />
                <PRFVDisplay />
                <ESRDisplay />
                <QDisplay />
                <CeffDisplay />
              </Col>
              <Col md={6}>
                <ESLDisplay />
                <ZDisplay />
                <ZPhaseDisplay />
                <S11Display />
                <S21Display />
                <LmaxDisplay />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12}>
                <FrequencyControl />
              </Col>
              <Col xs={12} md={6}>
                <AmbientTemperatureControl />
              </Col>
              <Col xs={12} md={6}>
                <MaxAllowedTemperatureControl />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="panel-footer" style={{textAlign: "right"}}>
          <ResourceButtons />
        </div>
      </div>
    )
  }
}

export default RFParametersPanel;
