import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'

@observer
class S21Display extends React.Component {

  render() {
    return (
      <UIStatic
        label="S21"
        value={calculator.magS21Decibels.toFixed(2)}
        unit="dB"
      />
    )
  }
}

export default S21Display;
