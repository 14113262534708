import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ControlLabel, FormControl } from 'react-bootstrap'
import { observer } from 'mobx-react'
import calculator from './calculator.js'

@observer
class UIStatic extends React.Component {
  formattedUnit() {
    return this.props.unit ? ` ${this.props.unit}` : ''
  }

  displayedValue() {
    if(calculator && calculator.calculating) {
      return (<span></span>)
    }
    else if(isNaN(this.props.value)) {
      return (<span></span>)
    }
    else {
      return (
        <span>
          {this.props.prefix}
          {this.props.value}
          {this.props.unit && 
            <span>{this.formattedUnit()}</span>
          }
        </span>
      )
    }
  }

  render() {
    return (
      <Row>
        <Col xs={6}>
          <div className="text-right">
            <ControlLabel>{this.props.label}</ControlLabel>
          </div>
        </Col>
        <Col xs={6}>
          <FormControl.Static>{this.displayedValue()}</FormControl.Static>
        </Col>
      </Row>
    )
  }
}

UIStatic.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  unit: PropTypes.string
}

export default UIStatic;
