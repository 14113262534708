import React from 'react';
import { Clearfix, ButtonGroup, Button, Glyphicon, Modal } from 'react-bootstrap'
import { observer } from 'mobx-react';
import constants from './constants.js'
import calculator from './calculator.js'
import loginStore from './loginStore'

import OrderSampleButton from './OrderSampleButton'
import OrderSampleModal from './OrderSampleModal'
import SParametersButton from './SParametersButton'
import SParametersModal from './SParametersModal'

var jsPDF = require('jspdf')

@observer
class ResourceButtons extends React.Component {
  constructor() {
    super()
    this.state = {pdfButtonText: "Download PDF", pdfButtonDisabled: false, showLoginModal: false}
  }

  handlePdfClick() {
    console.log('handlePdfClick')
    loginStore.requireLoggedInUser(() => {
      console.log('generatepdf')
      this.generatePdf()
    })
  }

  handleCadModelClick() {
    loginStore.requireLoggedInUser(() => {
      window.location = calculator.cadModelFileUrl
    })
  }

  handleSParameterClick() {
    loginStore.requireLoggedInUser(() => {
      window.location = calculator.sParameterFileUrl
    })
  }

  generatePdf() {
    window.scrollTo(0, 0); // Important, if we don't scroll to the top, it gets cut off

    let self = this
    this.setState({pdfButtonText: "Generating PDF", pdfButtonDisabled: true})

    let root = $('.MLCSoft');

    root.find('svg').each(function() {
      $(this).addClass('screenshot-svg')

      let canvas = document.createElement('canvas')
      canvas.className = "screenshot-canvas"
      let svgString = (new XMLSerializer()).serializeToString(this)

      canvg(canvas, svgString)
      $(canvas).insertAfter(this)
      $(this).hide()
    })

    $('.hide-on-pdf').hide();

    html2canvas(root[0], {
      allowTaint: false,
      letterRendering: true,
      logging: false,
      windowWidth: 1200,
      windowHeight: 1200
    }).then(function(canvas) {
      var imgData = canvas.toDataURL('image/png')

      $('.screenshot-canvas').remove()
      $('.screenshot-svg').show().removeClass('screenshot-svg')
      $('.hide-on-pdf').show();

      var doc = new jsPDF('p', 'mm')
      doc.addImage(imgData, 'PNG', 0, 20, 200, 200)
      doc.save(`${calculator.partNumber}.pdf`)
      self.setState({pdfButtonText: "PDF Saved", pdfButtonDisabled: false})

      setTimeout(() => { self.setState({pdfButtonText: "Download PDF"}) }, 5 * 1000)
    })
    .catch(function(e) {
      console.log("error:", e);
      $('.screenshot-canvas').remove()
      $('.screenshot-svg').show().removeClass('screenshot-svg')
      $('.hide-on-pdf').show();
      self.setState({pdfButtonText: "Unable to generate PDF", pdfButtonDisabled: true})
      alert("Sorry, this browser is unable to generate PDFs. Please try the most recent version of Chrome, Firefox, or Internet Explorer.")
    })
  }

  render() {
    return (
      <div>
        <OrderSampleModal />
        <SParametersModal />
        <ButtonGroup className="hidden-xs">
          <Button bsStyle="default" bsSize="small" onClick={this.handlePdfClick.bind(this)} disabled={this.state.pdfButtonDisabled}><Glyphicon glyph="file"></Glyphicon> {this.state.pdfButtonText}</Button>

          <OrderSampleButton />

          {(!loginStore.loggedIn || calculator.cadModelFileUrl) &&
            <Button bsStyle="default" bsSize="small" disabled={true} onClick={this.handleCadModelClick.bind(this)}><Glyphicon glyph="download-alt"></Glyphicon> Cad Model</Button>
          }
          { /*(!this.props.userId || calculator.sParameterFileUrl) && 
            <Button bsStyle="default" bsSize="small" onClick={this.handleSParameterClick.bind(this)}><Glyphicon glyph="download-alt"></Glyphicon> S-Parameters</Button>
            */}
          { (calculator.sParameters.length > 0) && 
            <SParametersButton />
          }
        </ButtonGroup>
        <Clearfix visibleXsBlock>
          <ButtonGroup className="visible-xs-block">
            <OrderSampleButton block={true} />
            {(!loginStore.loggedIn || calculator.cadModelFileUrl) &&
              <Button bsStyle="default" bsSize="small" disabled={true} block onClick={this.handleCadModelClick.bind(this)}><Glyphicon glyph="download-alt"></Glyphicon> Cad Model</Button>
            }
            { (!loginStore.loggedIn || calculator.sParameterFileUrl) && 
              <Button bsStyle="default" bsSize="small" block onClick={this.handleSParameterClick.bind(this)}><Glyphicon glyph="download-alt"></Glyphicon> S-Parameters</Button>
            }
          </ButtonGroup>
        </Clearfix>
      </div>
    )
  }
}

export default ResourceButtons;
