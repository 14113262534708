const constants = {
  chartYAxis1Color: "#0000FF",
  chartYAxis2Color: "#FF0000",
  sampleUrl: "https://www.johansontechnology.com/request-a-sample",
  initialChartMode1: "s11_mag",
  initialChartMode2: "adjusted_esr_log",
  initialNumberOfGraphPoints: 200,
  initialAmbientTemperatureCelcius: 65,
  initialMaxAllowedTemperatureCelcius: 125,
  minAllowedTemperatureCelcius: -55,
  maxAllowedTemperatureCelcius: 150,
  additionalCapacitorRecordsToFetch: 10,
  maxLmaxFrequency: 400,
  logSlope: 1,
  fcCutoff: 4000
}

export default constants;
