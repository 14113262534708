import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UISelect from './UISelect.js'

@observer
class PackagingCodeControl extends React.Component {
  render() {
    return (
      <UISelect
        label="Packaging"
        value={calculator.packagingCode}
        onChange={(e) => calculator.packagingCode = e.target.value }
        options={calculator.availablePackagingCodes}
        bsSize="sm"
        requireLoggedInUser={true}
      />
    )
  }
}

export default PackagingCodeControl;
