import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'

@observer
class ZDisplay extends React.Component {

  render() {
    return (
      <UIStatic
        label="|Z|"
        value={calculator.zOhms && calculator.zOhms.toFixed(1)}
        unit="Ω"
      />
    )
  }
}

export default ZDisplay;
