import React from 'react';
import { Button, ButtonGroup, Glyphicon, Modal } from 'react-bootstrap'
import { observer } from 'mobx-react';
import sParameterOrder from './sParameterOrder.js'

@observer
class SParametersModal extends React.Component {
  close = () => {
    sParameterOrder.closeModal()
  }

  submitRequest = () => {
    sParameterOrder.submitRequest()
  }

  render() {
    return (
      <Modal show={sParameterOrder.showOrderModal} onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title>Send Request for S-Parameters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you wish to send a request for S-Parameters for { sParameterOrder.part }? Your request will typically be processed within the next 24 hours.</p>

          { sParameterOrder.loading ? 
            <div style={{textAlign: "center"}}>
              <p>Please wait...</p>
            </div>
            :
            <div style={{textAlign: "center"}}>
              <ButtonGroup>
                <Button bsStyle="primary" onClick={this.submitRequest}><Glyphicon glyph="envelope"></Glyphicon> Submit Request</Button>
                <Button bsStyle="default" onClick={this.close}>Cancel</Button>
              </ButtonGroup>
            </div>
          }
        </Modal.Body>
      </Modal>
    )
  }
}

export default SParametersModal
