import React from 'react'
import { Children } from 'react'
import { Row, Col, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { observer } from 'mobx-react';
import cart from './cart.js'

import OrderSampleTextField from './OrderSampleTextField.js'
import OrderSampleSelectField from './OrderSampleSelectField.js'

const countries = [
"Albania",
"Algeria",
"American Samoa",
"Andorra",
"Angola",
"Anguilla",
"Antarctica",
"Antigua and Barbuda",
"Argentina",
"Aruba",
"Australia",
"Austria",
"Bahamas",
"Bangladesh",
"Barbados",
"Belgium",
"Belize",
"Benin",
"Bermuda",
"Bhutan",
"Bolivia, Plurinational State of",
"Bosnia and Herzegovina",
"Botswana",
"Brazil",
"British Indian Ocean Territory",
"Brunei Darussalam",
"Bulgaria",
"Burkina Faso",
"Burundi",
"Cambodia",
"Cameroon",
"Canada",
"Cape Verde",
"Cayman Islands",
"Central African Republic",
"Chad",
"Chile",
"China",
"Colombia",
"Comoros",
"Congo",
"Congo Republic",
"Costa Rica",
"Croatia",
"Cyprus",
"Czech Republic",
"Denmark",
"Djibouti",
"Dominica",
"Dominican Republic",
"Ecuador",
"El Salvador",
"Equatorial Guinea",
"Eritrea",
"Estonia",
"Ethiopia",
"Fiji",
"Finland",
"France",
"French Guiana",
"French Polynesia",
"French Southern Territories",
"Gabon",
"Gambia",
"Germany",
"Ghana",
"Greece",
"Greenland",
"Grenada",
"Guadeloupe",
"Guam",
"Guatemala",
"Guernsey",
"Guinea",
"Guinea-Bissau",
"Guyana",
"Haiti",
"Heard Island and McDonald Islands",
"Holy See",
"Honduras",
"Hong Kong",
"Hungary",
"Iceland",
"India",
"Indonesia",
"Ireland",
"Israel",
"Italy",
"Jamaica",
"Japan",
"Jersey",
"Kenya",
"Kiribati",
"Korea",
"Lao People's Democratic Republic",
"Latvia",
"Lesotho",
"Liechtenstein",
"Lithuania",
"Luxembourg",
"Macau",
"Macedonia, the former Yugoslav Republic of",
"Madagascar",
"Malawi",
"Malaysia",
"Maldives",
"Mali",
"Malta",
"Marshall Islands",
"Martinique",
"Mauritania",
"Mauritius",
"Mayotte",
"Mexico",
"Micronesia, Federated States of,",
"Monaco",
"Montenegro",
"Montserrat",
"Morocco",
"Mozambique",
"Namibia",
"Nauru",
"Nepal",
"Netherlands",
"Netherlands Antilles",
"New Caledonia",
"New Zealand",
"Nicaragua",
"Niger",
"Nigeria",
"Niue",
"Norfolk Island",
"Northern Mariana Islands",
"Norway",
"Palau",
"Panama",
"Papua New Guinea",
"Paraguay",
"Peru",
"Philippines",
"Pitcairn",
"Poland",
"Portugal",
"Puerto Rico",
"Qatar",
"Romania",
"Russia",
"Rwanda",
"Saint Barth&#0233;lemy",
"Saint Helena, Ascension and Tristan da Cunha",
"Saint Kitts and Nevis",
"Saint Lucia",
"Saint Martin (French part)",
"Saint Pierre and Miquelon",
"Saint Vincent and the Grenadines",
"Samoa",
"San Marino",
"Sao Tome and Principe",
"Saudi Arabia",
"Senegal",
"Serbia",
"Seychelles",
"Sierra Leone",
"Singapore",
"Slovakia",
"Slovenia",
"Solomon Islands",
"South Africa",
"South Georgia and the South Sandwich Islands",
"Spain",
"Sri Lanka",
"Suriname",
"Svalbard and Jan Mayen",
"Swaziland",
"Sweden",
"Switzerland",
"Taiwan",
"Tanzania, United Republic of",
"Thailand",
"Timor-Leste",
"Togo",
"Tokelau",
"Tonga",
"Trinidad and Tobago",
"Tunisia",
"Turkey",
"Turkmenistan",
"Turks and Caicos Islands",
"Tuvalu",
"Uganda",
"Ukraine",
"United Arab Emirates",
"United Kingdom",
"United States",
"Uruguay",
"Vanuatu",
"Venezuela",
"Viet Nam",
"Virgin Islands, British",
"Virgin Islands, U.S.",
"Wallis and Futuna",
"Western Sahara",
"Zambia"
]

const countryMeta = {
  "United States": {
    "countryCode": "usa",
    "fieldName": "state",
    "stateCodes": ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
        "Connecticut", "Delaware", "Florida", "Georgia", "Guam", "Hawaii",
        "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
        "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan",
        "Minnesota", "mississippi", "Missouri", "Montana", "Nebraska",
        "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York",
        "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon",
        "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
        "Tennessee", "Texas", "Utah", "Vermont", "Virgin Islands", "Virginia",
        "Washington", "Washington D.C.","West Virginia", "Wisconsin", "Wyoming"],
  },
  "Canada": {
    "countryCode": "canada",
    "stateCodes": [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Nova Scotia",
        "Ontario",
        "Quebec",
        "Saskatchewan"
    ],
    "fieldName": "region",
  }
}

@observer
class OrderSampleForm extends React.Component {
  handleFormSubmission(e) {
    cart.attemptSubmission()
    if(!cart.submissionAllowed) {
      e.preventDefault()
    }
  }

  states() {
    if(cart.country && countryMeta[cart.country]) {
      return (
        <Col sm={6}>
          <OrderSampleSelectField label="State" name={countryMeta[cart.country].fieldName} options={countryMeta[cart.country].stateCodes} />
        </Col>
      )
    }
    else {
      return null
    }
  }


  render() {
    return (
      <form method="GET" action="https://www.johansontechnology.com/components/com_request/request.php" onSubmit={this.handleFormSubmission.bind(this)}>
        <input type="hidden" name="type" value="Sample" />
        <input type="hidden" name="ip" value={cart.serverIp} />
        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <OrderSampleTextField label="First Name" name="fname" />
              </Col>
              <Col sm={6}>
                <OrderSampleTextField label="Last Name" name="lname" />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <OrderSampleTextField label="Email" name="email"  />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <OrderSampleTextField label="Company Name" name="company"  />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <OrderSampleTextField label="Address" name="address1"  />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <OrderSampleTextField label="City" name="city"  />
              </Col>
              <Col sm={4}>
                <OrderSampleTextField label="Postal Code" name="zip"  />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <OrderSampleSelectField label="Country" name="country" options={countries} />
              </Col>
              { this.states() }
            </Row>
            <Row>
              <Col sm={9}>
                <OrderSampleTextField label="Phone" name="phone"  />
              </Col>
              <Col sm={3}>
                <OrderSampleTextField label="Extension" name="ext"  />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <OrderSampleTextField label="Fax" name="fax"  />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col sm={3}>
                <OrderSampleTextField label="Qty" name="qtya1" disableAutoComplete={true} />
              </Col>
              <Col sm={9}>
                <OrderSampleTextField label="Part Number" name="part1" disableAutoComplete={true} />
              </Col>
            </Row>
            { cart.part2 && 
              <Row>
                <Col sm={3}>
                  <OrderSampleTextField label="Qty" name="qtya2" disableAutoComplete={true} />
                </Col>
                <Col sm={9}>
                  <OrderSampleTextField label="Part Number" name="part2" disableAutoComplete={true} />
                </Col>
              </Row>
            }
            { cart.part3 && 
              <Row>
                <Col sm={3}>
                  <OrderSampleTextField label="Qty" name="qtya3" disableAutoComplete={true} />
                </Col>
                <Col sm={9}>
                  <OrderSampleTextField label="Part Number" name="part3" disableAutoComplete={true} />
                </Col>
              </Row>
            }
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <FormGroup>
              <ControlLabel>Message</ControlLabel>
              <FormControl componentClass="textarea" name="message"/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Button block bsStyle="primary" bsSize="lg" type="submit">Submit Order</Button>
          </Col>
        </Row>
      </form>
    )
  }
}

export default OrderSampleForm
