import { observable, computed, action, runInAction } from 'mobx'
var uniq = require('lodash/uniq')
var slice = require('lodash/slice')

class ObservableDataStore {
  @observable fname
  @observable lname
  @observable email
  @observable company
  @observable address1
  @observable address2
  @observable city
  @observable zip
  @observable country
  @observable phone
  @observable ext
  @observable fax
  @observable qtya1
  @observable part1
  @observable qtya2
  @observable part2
  @observable qtya3
  @observable part3
  @observable message
  @observable serverIp

  @observable showOrderModal = false
  @observable showOrderForm = false

  @observable __hasAttemptedSubmission = false

  attemptSubmission() {
    this.__hasAttemptedSubmission = true
  }

  addPart(part, qty = 1) {
    if(!this.part1) {
      this.part1 = part
      this.qtya1 = qty
    }
    else if(!this.part2) {
      this.part2 = part
      this.qtya2 = qty
    }
    else if(!this.part3) {
      this.part3 = part
      this.qtya3 = qty
    }
    else {
      alert("Your cart is full")
    }
  }

  removePart1() {
    this.part1 = this.part2
    this.part2 = this.part3
    this.part3 = null
    this.qtya1 = this.qtya2
    this.qtya2 = this.qtya3
    this.qtya3 = null
  }

  removePart2() {
    this.part2 = this.part3
    this.part3 = null
    this.qtya2 = this.qtya3
    this.qtya3 = null
  }

  removePart3() {
    this.part3 = null
    this.qtya3 = null
  }

  @computed get readyForOrder() {
    return this.part1 ? true : false
  }

  @computed get validationErrors() {
    // Until we have attempted to submit the form do not show any validation
    // errors
    if(!this.__hasAttemptedSubmission) {
      return {}
    }

    let errors = {}

    if(!this.fname) {
      errors["fname"] = "This field is required"
    }

    if(!this.lname) {
      errors["lname"] = "This field is required"
    }

    if(!this.email) {
      errors["email"] = "This field is required"
    }
    else if(!this.email.match(/.+@.+\..+/)) {
      errors["email"] = "Invalid email"
    }
    if(!this.company) {
      errors["company"] = "This field is required"
    }
    if(!this.address1) {
      errors["address1"] = "This field is required"
    }
    if(!this.city) {
      errors["city"] = "This field is required"
    }
    if(!this.zip) {
      errors["zip"] = "This field is required"
    }
    if(!this.country) {
      errors["country"] = "This field is required"
    }
    if(!this.phone) {
      errors["phone"] = "This field is required"
    }
    if(!this.qtya1) {
      errors["qtya1"] = "This field is required"
    }
    if(!this.part1) {
      errors["part1"] = "This field is required"
    }
    return errors
  }

  @computed get submissionAllowed() {
    return Object.keys(this.validationErrors).length === 0
  }
}
const cart = new ObservableDataStore();

export default cart;
