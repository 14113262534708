import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'

@observer
class S11Display extends React.Component {

  render() {
    return (
      <UIStatic
        label="S11"
        value={calculator.magS11Decibels.toFixed(2)}
        unit="dB"
      />
    )
  }
}

export default S11Display;
