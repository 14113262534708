import React from 'react';
import { observer } from 'mobx-react'
import calculator from './calculator.js'
import UISelect from './UISelect.js'

@observer
class ToleranceCodeControl extends React.Component {
  render() {
    return (
      <UISelect
        label="Tolerance"
        value={calculator.toleranceCode}
        onChange={(e) => calculator.toleranceCode = e.target.value}
        options={calculator.availableToleranceCodes}
        requireLoggedInUser={true}
        bsSize="sm"
      />
    )
  }
}

export default ToleranceCodeControl;
