import React from 'react';
import { observer } from 'mobx-react';
import calculator from './calculator.js'
import UIStatic from './UIStatic.js'
import UnitConverter from './UnitConverter.js'

@observer
class PRFVDisplay extends React.Component {
  render() {
    let prefix, value, unit
    if(calculator.prfvMegahertz > 0) {
      value = UnitConverter.megahertzToGigahertz(calculator.prfvMegahertz).toFixed(2)
      unit = "GHz"
    }
    else if(calculator.sParameters.length > 0) {
      prefix = ">"
      value = "10"
      unit = "GHz"
    }

    return (
      <UIStatic
        prefix={prefix}
        label="2nd PRF"
        value={value}
        unit={unit}
      />
    )
  }
}

export default PRFVDisplay;
